import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { colors, fontSize, tourColors } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { first, isEmpty, keys, size } from 'lodash';
import directions from 'global/img/directions.svg';
import {
  getDiffInDays,
  getUtcDateFormatted,
  getUtcDateFormattedFull,
} from 'utils/timeFormatHelpers';
import {
  filterOrdersByBreaksAndReloads,
  getOrdersAddress,
  getOrdersByLocation,
  getOrdersByStop,
  hasHighPriorityOrders,
} from '../../../utils/OrdersHelpers';
import Button from '../../Form/Button';
import { makeShareNavLink, makeDesktopNavLink } from '../../../utils/SolutionExport';
import { isMobileDevice, isSameLocation } from '../../../utils/helpers';
import StopDetails from './StopDetails';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import { getDemand, showIcon } from '../../../utils/SolutionHelpers';
import { getSafeValue, setSafeValue } from '../../../utils/security';
import { StyledInfoEllipsis } from '../Global/WizardStyled';
import StopIcon from './StopIcon';
import { getVehicleProfile } from '../../../utils/FleetHelpers';

const { secondaryTextColor, transparentBlackColor, secondaryBorderColor, white } = colors;
const { small, medium } = fontSize;

const StyledStopLine = styled.div(
  {
    gridArea: 'stopLine',
  },
  ({ isActive, color, isDepot, isReturnLocation }) => ({
    borderLeft: '0.2rem solid',
    borderColor: isActive ? color : '#ADADAD',
    height: isReturnLocation ? '50%' : '100%',
    marginTop: isDepot ? '1.9rem' : '0',
    marginBottom: isReturnLocation ? '1.9rem' : '0',
  }),
);

const StyledStop = styled.div(
  {
    lineHeight: '3rem',
    padding: '0rem 0.2rem',
    textRendering: 'geometricPrecision',
    color: secondaryTextColor,
    fontSize: medium,
    borderTop: `1px solid ${secondaryBorderColor}`,
    minHeight: '3.8rem',
  },
  ({ isActive }) => ({
    backgroundColor: isActive ? white : 'transparent',
    cursor: isActive ? 'normal' : 'pointer',
  }),
);

const StyledIconContainer = styled.div(
  {
    borderTop: `1px solid ${secondaryBorderColor}`,
    display: 'grid',
    placeItems: 'center',
    gridTemplateAreas: '"stopLine"',
  },
  ({ isActive, numberOfOrders }) => ({
    cursor: isActive ? 'normal' : 'pointer',
    gridRow: isActive && `span ${2 + numberOfOrders}`,
  }),
);

const StyledBtn = styled.div({
  transition: 'all 0.35s cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  position: 'relative',
  top: '10px',
});

const getStopType = (stop) => {
  const activities = stop.activities;
  if (activities.length === 1) return activities[0].type;

  const activityTypes = (activities || []).reduce((t, activity) => {
    if (!getSafeValue(t, activity.type)) setSafeValue(t, activity.type, 0);
    setSafeValue(t, activity.type, getSafeValue(t, activity.type) + 1);
    return t;
  });
  const types = keys(activityTypes);

  if (size(types) === 1) return first(types);
  return 'mixed';
};

const StyledAddressWrapper = styled(StyledInfoEllipsis)(
  {
    lineHeight: '1rem',
    textAlign: 'left !important',
    fontWeight: `400`,
    maxWidth: '10rem',
  },
  ({ isActive, isDepot, isReturnLocation }) => ({
    fontWeight: isActive ? `700` : `400`,
    position: 'relative',
    padding: isDepot || isReturnLocation ? '1.4rem 0' : '1rem 0',
    top: 0,
  }),
);

const StyledTimes = styled.div(
  {
    position: 'relative',

    lineHeight: '1rem',
    padding: '0.5rem 0',
    color: transparentBlackColor,
    textAlign: 'center',
    fontSize: small,
    fontWeight: '400',
    backgroundImage: 'none',
    div: {
      display: 'block',
      color: secondaryTextColor,
    },
  },
  ({ isActive, isDepot, isReturnLocation }) => ({
    backgroundColor: isActive ? white : 'transparent',
    cursor: isActive || isDepot || isReturnLocation ? 'normal' : 'pointer',
    top: isDepot || isReturnLocation ? '16px' : '8px',
  }),
);

const StyledTimesWrapper = styled(StyledTimes)`
  div:last-of-type {
    color: ${transparentBlackColor};
    font-weight: 400;
  }
  ,
  &:after {
    position: relative;
    right: -2rem;
    top: -1.2rem;
    color: green;
    content: '${(props) => props.departureDay}';
  }
  ,
  &:before {
    position: relative;
    right: -2rem;
    top: 0.6rem;
    color: green;
    content: '${(props) => props.arrivalDay}';
  }
`;

const StyledSpace = styled.span`
  margin-bottom: ${(props) => (props.space ? props.space : '1.5rem')};
`;

const Stop = withTranslation(
  ({
    user,
    index,
    stop,
    prevStop,
    request,
    isDepot,
    isReturnLocation,
    isLastStop,
    tourStartDate,
    isStopActive,
    onStopClick,
    tourPlanner,
    demandLabel,
    isDemandVisible,
    tourId,
    typeId,
    unassigned,
    translations: {
      map: { depotMarkerTrans },
      tourSolution: {
        shiftExtended,
        day,
        tourDetails: { arrivalTrans, stopTrans },
      },
      wizard: {
        toursDetails: { getDirections },
      },
    },
  }) => {
    let tourPlannerLabel = null;

    if (isDepot) {
      tourPlannerLabel = tourPlanner && tourPlanner.value && tourPlanner.location.label;
      if (!tourPlannerLabel) {
        const ordersForDepot = getOrdersByLocation(request.orders, stop.location);
        const filteredOrders = filterOrdersByBreaksAndReloads(ordersForDepot);
        const hasUnassigned = unassigned?.length > 0;
        const unassignedDepot =
          hasUnassigned &&
          ordersForDepot &&
          ordersForDepot.some((order) => unassigned.includes(order.InternalID));
        tourPlannerLabel =
          !unassignedDepot &&
          !isEmpty(filteredOrders) &&
          filteredOrders[0].Address !== filteredOrders[0].ID
            ? filteredOrders[0].Address
            : null;
      }
    }

    if (isReturnLocation) {
      tourPlannerLabel = isSameLocation(tourPlanner.location, tourPlanner.returnLocation)
        ? arrivalTrans
        : tourPlanner.returnLocation.label;
    }

    const ordersInStop = getOrdersByStop(request.orders, stop);
    const stopType = getStopType(stop);
    const hasHighPrioOrders = hasHighPriorityOrders(ordersInStop);
    const address = isDepot ? depotMarkerTrans : getOrdersAddress(ordersInStop);
    const defaultVal = isDepot || isReturnLocation ? null : `${stopTrans} ${index}`;
    const defaultLabel = defaultVal || (isDepot ? depotMarkerTrans : arrivalTrans);
    const label = address || tourPlannerLabel || defaultLabel;
    const fleetType = getVehicleProfile(
      typeId,
      tourPlanner.vehicles,
      tourPlanner.vehicleProfiles,
    )?.fleetType;
    const offset = tourPlanner.offset;

    const linkUrl = isMobileDevice()
      ? makeShareNavLink(stop, user.language, request.orders, tourPlanner, fleetType)
      : makeDesktopNavLink(stop, prevStop, user.language, request.orders, tourPlanner, fleetType);
    const totalDemand =
      !isDemandVisible || !ordersInStop ? [0] : getDemand({ orders: ordersInStop });
    const hasJobs = totalDemand && totalDemand[0] > 0;
    const isActive = isStopActive;
    const iconContent = showIcon(stopType) ? null : index;

    const onClick = useCallback(
      (i) => {
        if (!(isDepot && ordersInStop.length === 0) && !isReturnLocation) {
          onStopClick(i);
        }
        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_STOP_CLICK);
      },
      [onStopClick, isDepot, isReturnLocation, ordersInStop],
    );

    const onClickGetDirections = useCallback(() => {
      AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_STOP_TO_WEGO);
      if (isMobileDevice()) {
        const amount = !isEmpty(ordersInStop) ? size(ordersInStop) : 1;
        const stopInfo = {
          stopsInTour: 1,
          ordersInTour: amount,
          dropsInTour: totalDemand,
          dispatchMode: 'linkWeGo',
          isDemo: user.isLastOrdersAddedDemo,
        };

        AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_DISPATCH, stopInfo);
      }
    }, [ordersInStop, totalDemand, user]);

    const iconColorSet = tourColors[tourId % tourColors.length];
    const iconColor =
      stopType === 'pickup'
        ? iconColorSet.pickup
        : stopType === 'mixed'
        ? { inner: 'grey', outer: 'grey', text: 'white' }
        : iconColorSet.delivery;

    const arrivalDay = getDiffInDays(stop.time.arrival, tourStartDate, offset);
    const departureDay = getDiffInDays(stop.time.departure, tourStartDate, offset);
    const timeTitle = `${getUtcDateFormattedFull(
      stop.time.arrival,
      offset,
    )}/${getUtcDateFormattedFull(stop.time.departure, offset)}`;

    return (
      <>
        <StyledIconContainer
          onClick={() => onClick(index)}
          isActive={isActive}
          key={`stop-${index}-0`}
          numberOfOrders={ordersInStop.length}
        >
          <StyledStopLine
            isActive={isActive}
            color={iconColor.inner}
            isDepot={isDepot}
            isReturnLocation={isReturnLocation}
          />
          <StopIcon
            color={iconColor}
            text={iconContent}
            isDepot={isDepot}
            isReturnLocation={isReturnLocation}
            stopType={stopType}
            highPriority={hasHighPrioOrders}
          />
        </StyledIconContainer>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-1`}>
          <StyledAddressWrapper
            isReturnLocation={isReturnLocation}
            isActive={isActive}
            title={label}
            data-test-id="tdAddress"
          >
            {label}
          </StyledAddressWrapper>
        </StyledStop>
        <StyledStop
          data-test-id="tdDemand"
          title={totalDemand && totalDemand.join(', ')}
          onClick={() => onClick(index)}
          isActive={isActive}
          key={`stop-${index}-2`}
        >
          {index > 0 && hasJobs && totalDemand && (
            <>
              {totalDemand[0]}
              {totalDemand.length > 1 && '...'}
            </>
          )}
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-3`}>
          <StyledTimesWrapper
            arrivalDay={arrivalDay >= 1 ? `+${arrivalDay}` : ''}
            departureDay={departureDay >= 1 && !isLastStop ? `+${departureDay}` : ''}
            title={
              departureDay
                ? `${shiftExtended} ${departureDay} ${day}${departureDay > 1 ? 's' : ''}`
                : timeTitle
            }
          >
            <div>{isDepot ? '' : getUtcDateFormatted(stop.time.arrival, offset)}</div>
            <div>{isReturnLocation ? '' : getUtcDateFormatted(stop.time.departure, offset)}</div>
          </StyledTimesWrapper>
        </StyledStop>
        <StyledStop isActive={isActive} key={`stop-${index}-4`}>
          <StyledBtn isActive={isActive}>
            {linkUrl && (
              <a
                id={`button-wego-${index}`}
                href={linkUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={getDirections}
                onClick={() => onClickGetDirections(stop)}
              >
                <Button inverted={!isActive} icon={directions} />
              </a>
            )}
          </StyledBtn>
        </StyledStop>
        <StyledStop onClick={() => onClick(index)} isActive={isActive} key={`stop-${index}-5`} />
        {isActive && ordersInStop && (
          <StopDetails
            orders={ordersInStop}
            isDemandVisible={isDemandVisible}
            demandLabel={demandLabel}
          />
        )}
        {isReturnLocation && <StyledSpace space="1.3rem" />}
      </>
    );
  },
);

export default withTranslation(Stop);
