import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutingTourInfo, selectTourById } from 'actions';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { camelCase, includes } from 'lodash';
import { getDemand, getLastValidIteration } from 'utils/SolutionHelpers';
import { tourToRoutingRequest } from 'utils/RoutingConverter';
import Tour from './Tour';
import { isMobileDevice } from '../../../utils/helpers';
import TableCheckbox from '../../Form/TableCheckbox';

const { primaryBorderColor } = colors;
const { small } = fontSize;

const gridSizes = (isMobile, isHeader) => {
  if (!isMobile && !isHeader) return '0.7rem 0.1fr 0.3fr 0.8fr 1.2fr 1fr 1fr 0.3fr 0.1fr 0.8rem';
  if (isHeader) return '0.6rem 0.2rem 0.1fr 0.1fr 0.1fr 0.5fr 1fr 1fr 0.75fr 0.1fr 0.1rem';
  return '0.5rem 0.5fr 0.1fr 0.8fr 1.2fr 1.1fr 1.1fr 0.1fr 0.1fr 0.5rem';
};

const StyledGrid = styled.div(({ isMobile }) => ({
  display: 'grid',
  gridTemplateColumns: gridSizes(isMobile),
  div: {
    textAlign: 'center',
    verticalAlign: 'text-bottom',
  },
  'div:nth-last-of-type(-n + 9)': {
    borderBottom: 'none',
  },
}));

const StyledHeaderGrid = styled.div(({ isMobile, isHeader }) => ({
  display: 'grid',
  position: 'fixed',
  top: '23.5rem',
  gridTemplateColumns: gridSizes(isMobile, isHeader),
}));

const StyledHeader = styled.div`
  text-transform: uppercase;
  padding: 0 0.2rem;
  text-align: center;
  border-bottom: 1px solid ${primaryBorderColor};
  text-rendering: geometricPrecision;

  & > span {
    font-size: ${small};
    opacity: 0.75;
    position: relative;
    top: -2px;
  }
`;

const Tours = ({
  oAuth,
  id,
  lastIterationTourData: { fleet, plan, tours = [], uploaded },
  onTourChange,
  selectedTours,
  solution,
  tourPlanner,
  translations: {
    tourSolution: { tourHeaders, selectAllNoneTitle },
  },
  user,
  display,
}) => {
  const dispatch = useDispatch();
  const orders = useSelector((state) => state.orders[solution.show]);
  const elemRef = useRef();
  const [shouldScroll, setShouldScroll] = useState(false);
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const tempOrders = solution.requests[solution.show].tempOrders;

  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(tour, tourPlanner, tempOrders || orders),
          tourId: index,
          solutionId: solution.show,
        }),
      ),
    [dispatch, oAuth, tourPlanner, solution.show, orders, tempOrders],
  );

  const onClick = useCallback(
    (index, tour, shiftKey = undefined) => {
      if (shiftKey) {
        const lastSelectedIndex = selectedTours[selectedTours.length - 1] || 0;
        const start = Math.min(lastSelectedIndex, index);
        const end = Math.max(lastSelectedIndex, index);

        handleSelectTourById({ selectMultiple: { start, end } });
        for (let i = start; i <= end; i++) handleGetRoutingTourInfo(i, tours[i]);
      } else {
        handleSelectTourById({ index });
        handleGetRoutingTourInfo(index, tour);
      }
    },
    [handleGetRoutingTourInfo, handleSelectTourById, selectedTours],
  );

  const onClickViewTour = useCallback(
    (index, tour) => () => {
      handleSelectTourById({ selectedTour: index });
      handleSelectTourById({ selectOne: index });
      onTourChange(tour);
    },
    [onTourChange, handleSelectTourById, selectedTours],
  );

  const selectAllTours = useCallback(() => {
    handleSelectTourById({ selectAll: tours.length });
    Promise.all(tours.map((tour, index) => handleGetRoutingTourInfo(index, tour)));
  }, [tours, handleSelectTourById, handleGetRoutingTourInfo]);

  const unselectAllTours = useCallback(() => {
    handleSelectTourById({ unselectAll: true });
  }, [handleSelectTourById]);

  useEffect(() => {
    if (display.routeIds.length === 0) {
      handleSelectTourById({ index: 0 });
      handleGetRoutingTourInfo(0, tours[0]);
    } else {
      handleSelectTourById({ selectedTour: undefined, selectOne: 0 });
    }
  }, []);

  useEffect(() => {
    if (elemRef.current)
      elemRef.current.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    return () => setShouldScroll(!shouldScroll);
  }, [selectedTours, shouldScroll, elemRef.current]);

  const tableHeader = useMemo(
    () => (
      <>
        {['', '', '#', ...tourHeaders, '', ''].map((header, index) => (
          <StyledHeader key={camelCase(`${header}-${index}`)}>
            {index === 2 ? (
              <TableCheckbox
                onSelect={selectAllTours}
                onUnselect={unselectAllTours}
                selected={selectedTours.length}
                total={tours.length}
                title={selectAllNoneTitle}
              />
            ) : (
              <span>{header}</span>
            )}
          </StyledHeader>
        ))}
      </>
    ),
    [tourHeaders, selectAllTours, unselectAllTours, tours, selectedTours],
  );

  const request = getLastValidIteration(solution.requests[0]).request;

  return (
    <>
      <StyledHeaderGrid id={id} isMobile={isMobileDevice()} isHeader>
        {tableHeader}
      </StyledHeaderGrid>
      <StyledGrid id={id} isMobile={isMobileDevice()}>
        {tours.map((tour, index) => {
          const isActive = includes(selectedTours, index);
          return (
            <Tour
              demand={!uploaded ? getDemand({ tour, plan }) : null}
              fleet={fleet}
              index={index}
              isActive={isActive}
              key={`tour-${index}`}
              onClickTour={onClick}
              onTourChange={onClickViewTour(index, tour)}
              request={request}
              tour={tour}
              user={user}
              tourPlanner={tourPlanner}
              elemRef={isActive ? elemRef : null}
            />
          );
        })}
      </StyledGrid>
    </>
  );
};

export default withTranslation(Tours);
