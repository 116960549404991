import { first, last, size } from 'lodash';
import config from 'config';
import { decode } from './flexpolyline';
import { FLEET_PROFILES } from '../actions';
import { AVOID_TYPES } from './territories/TerritoriesFromJson';
import { getOrdersByActivity, getOrdersByStop } from './OrdersHelpers';
import { getSafeValue } from './security';
import { findAverage } from './helpers';
import { findIntersectingAndOverlappingPolylines } from './RoutingHelpers';

const {
  routing: { characterCountLimit: urlCharacterLimit, routingUrlLength: urlLength },
} = config;

export const getGeoJSONfromRoutingResponse = (responses, tourId) => {
  const features = [];
  let total = 0;
  responses.forEach((response) => {
    if (response.stopsRequest) total += response.polylines.length;
  });

  let indexStart = 0;
  const visitedCoordinates = [];

  responses.forEach((response, idx) => {
    response.polylines.forEach((polyline, index) => {
      const stopsReq = response.stopsRequest;
      if (index === 0 && idx !== 0 && stopsReq) {
        const prevStopsReq = responses[idx - 1].stopsRequest;
        if (prevStopsReq) indexStart += responses[idx - 1].polylines.length;
      }

      const fromStop = stopsReq ? indexStart + index : index;
      const totalStops = stopsReq ? total : response.polylines.length;
      const coordinates = polyline.map((coord) => coord.reverse());
      visitedCoordinates.push(polyline);

      const bbox = coordinates.reduce(
        ([minX, minY, maxX, maxY], [x, y]) => [
          Math.min(minX, x),
          Math.min(minY, y),
          Math.max(maxX, x),
          Math.max(maxY, y),
        ],
        [180, 90, -180, -90],
      );

      features.push({
        bbox,
        geometry: {
          coordinates,
          type: 'LineString',
        },
        type: 'Feature',
        properties: {
          routeId: parseInt(tourId, 10),
          vehicle: {},
          isRouting: true,
          mode: response.mode,
          isStopsRequest: response.stopsRequest,
          fromStop,
          totalStops,
        },
      });
    });
  });

  const results = findIntersectingAndOverlappingPolylines(visitedCoordinates);

  results.intersections.forEach((coord) =>
    features.push({
      type: 'Feature',
      geometry: { coordinates: coord, type: 'Point' },
      properties: {
        isUndesired: true,
        intersectionCount: results?.intersections?.length,
        overlapCount: results?.overlaps?.length,
      },
    }),
  );

  results.overlaps.forEach((coord) => {
    const bbox = coord.reduce(
      ([minX, minY, maxX, maxY], [x, y]) => [
        Math.min(minX, x),
        Math.min(minY, y),
        Math.max(maxX, x),
        Math.max(maxY, y),
      ],
      [180, 90, -180, -90],
    );

    return features.push({
      bbox,
      type: 'Feature',
      geometry: { coordinates: coord, type: 'LineString' },
      properties: {
        isUndesiredLine: true,
        routeId: parseInt(tourId, 10),
        vehicle: {},
        isRouting: true,
      },
    });
  });

  return features;
};

export const getAvoidAreasFormatted = (areas) => {
  return areas
    .map((area) => {
      if (area.type === AVOID_TYPES.BOUNDING_BOX)
        return `bbox:${area.east},${area.south},${area.west},${area.north}`;

      if (area.type === AVOID_TYPES.POLYGON)
        return `polygon:${area.outer.map(({ lat, lng }) => `${lat},${lng}`).join(';')}`;

      if (area.type === AVOID_TYPES.ENCODED_POLYGON) return `polygon:${area.outer}`;
      return '';
    })
    .join('|');
};

export const getAvoidOptions = (avoid) => {
  if (!avoid) return null;
  return {
    ...(avoid.segments && { 'avoid[segments]': `${avoid.segments.join()}` }),
    ...(avoid.zoneIdentifiers && { 'avoid[zoneIdentifiers]': `${avoid.zoneIdentifiers.join()}` }),
    ...(avoid.areas && { 'avoid[areas]': getAvoidAreasFormatted(avoid.areas) }),
    ...(avoid.zoneCategories && {
      'avoid[zoneCategories]': `${avoid.zoneCategories.categories.join()};exceptZoneIds=${avoid.zoneCategories.exceptZoneIds.join()}`,
    }),
    ...(avoid.features && {
      'avoid[features]': avoid.features
        .map((feature) => (feature === 'motorway' ? 'controlledAccessHighway' : feature))
        .join(),
    }),
    ...(avoid.truckRoadTypes && { 'avoid[truckRoadTypes]': `${avoid.truckRoadTypes.join()}` }),
  };
};

export const getExcludeOptions = (exclude) => {
  return {
    ...{
      'exclude[countries]': `${exclude.countries.join()}`,
    },
  };
};

export const getVehicleOptions = (options) => {
  if (!options) return null;
  return {
    ...(FLEET_PROFILES.SCOOTER &&
      options.allowHighway && { 'scooter[allowHighway]': `${options.allowHighway}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.shippedHazardousGoods && {
        'vehicle[shippedHazardousGoods]': `${options.shippedHazardousGoods.join()}`,
      }),
    ...(FLEET_PROFILES.TRUCK &&
      options.grossWeight && { 'vehicle[grossWeight]': `${options.grossWeight}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.weightPerAxle && { 'vehicle[weightPerAxle]': `${options.weightPerAxle}` }),
    ...(FLEET_PROFILES.TRUCK && options.height && { 'vehicle[height]': `${options.height}` }),
    ...(FLEET_PROFILES.TRUCK && options.width && { 'vehicle[width]': `${options.width}` }),
    ...(FLEET_PROFILES.TRUCK && options.length && { 'vehicle[length]': `${options.length}` }),
    ...(FLEET_PROFILES.TRUCK &&
      options.tunnelCategory && { 'vehicle[tunnelCategory]': `${options.tunnelCategory}` }),
  };
};

export function splitToNChunks(array, n) {
  const result = [];
  for (let i = n; i > 0; i--) {
    result.push(array.splice(0, Math.ceil(array.length / i)));
  }
  return result;
}

export const createViaFromChunk = (chunk, nameHints) => {
  if (!chunk && !nameHints) return '';

  return chunk
    .map((stop) => {
      const nameHint = nameHints[stop.index];
      return `${stop.location.lat},${stop.location.lng}${nameHint ? `;nameHint=${nameHint}` : ''}`;
    })
    .join('&via=');
};

export const isSameLocation = (locationA, locationB) =>
  locationA.lat === locationB.lat && locationA.lng === locationB.lng;

export const getAllVias = (stop, stopOrders) => {
  const nameHints = stop.activities.map((activity) => {
    const orders = getOrdersByActivity(stopOrders, activity);
    return orders && orders.find((order) => order.NameHint !== undefined)?.NameHint;
  });
  const sideOfStreet = stop.activities.map((activity) => {
    const orders = getOrdersByActivity(stopOrders, activity);
    return orders && orders.find((order) => order.SideOfStreet !== undefined)?.SideOfStreet;
  });
  return stop.activities.reduce((activityAcc, activity, index) => {
    const location = activity.location || stop.location;
    if (isSameLocation(stop.location, location)) return activityAcc;

    activityAcc.push({
      location,
      jobId: activity.jobId,
      nameHint: nameHints[index],
      sideOfStreet: sideOfStreet[index],
    });

    return activityAcc;
  }, []);
};

export const filterParameters = (request) => {
  const paramsToFilter = ['avoid[truckRoadTypes]'];
  paramsToFilter.forEach((p) => delete request[p]);
  return request;
};

export const getActivityRoutingRequest = (
  request,
  stop,
  stopOrders,
  stopHint,
  stopSideOfStreet,
) => {
  const filtered = filterParameters(request);
  const vias = getAllVias(stop, stopOrders).map((via) => {
    const nameHint = via.nameHint ? `;nameHint=${via.nameHint}` : '';
    const sideOfStreet = via.sideOfStreet
      ? `;sideOfStreetHint=${via.sideOfStreet.lat},${via.sideOfStreet.lng};matchSideOfStreet=${via.sideOfStreet.matchSideOfStreet}`
      : '';

    return `${via.location.lat},${via.location.lng}${nameHint}${sideOfStreet}`;
  });

  const nameHint = stopHint ? `;nameHint=${stopHint}` : '';
  const sideOfStreet = stopSideOfStreet
    ? `;sideOfStreetHint=${stopSideOfStreet.lat},${stopSideOfStreet.lng};matchSideOfStreet=${stopSideOfStreet.matchSideOfStreet}`
    : '';
  return {
    ...filtered,
    departureTime: stop.time.arrival,
    origin: `${stop.location.lat},${stop.location.lng}${nameHint}${sideOfStreet}`,
    destination: `${stop.location.lat},${stop.location.lng}${nameHint}${sideOfStreet}`,
    transportMode: 'pedestrian',
    ...(vias && vias.length > 0 && { via: vias.join('&via=') }),
  };
};

export const createRoutingRequests = (request, vehicleOptions, tour, mode, orders) => {
  const requests = [];
  const polylines = [];
  const stopsWithPolylines = tour.stops.filter((stop) => stop.routeDetails?.polyline);
  const stopsOrders = tour.stops.map((stop) => getOrdersByStop(orders, stop));
  const stopsNameHints = stopsOrders.map(
    (stopOrder) => stopOrder && stopOrder.find((order) => order.NameHint !== undefined)?.NameHint,
  );
  const stopsSideOfStreet = stopsOrders.map(
    (stopOrder) =>
      stopOrder && stopOrder.find((order) => order.SideOfStreet !== undefined)?.SideOfStreet,
  );
  const originSOS = stopsSideOfStreet[0];
  const destinationSOS = stopsSideOfStreet[stopsSideOfStreet.length - 1];
  const originSideOfStreet = originSOS
    ? `;sideOfStreetHint=${originSOS.lat},${originSOS.lng};matchSideOfStreet=${originSOS.matchSideOfStreet}`
    : '';
  const destinationSideOfStreet = destinationSOS
    ? `;sideOfStreetHint=${destinationSOS.lat},${destinationSOS.lng};matchSideOfStreet=${destinationSOS.matchSideOfStreet}`
    : '';

  if (stopsWithPolylines.length > 0) {
    polylines.push(
      ...stopsWithPolylines
        .map((stop) => decode(stop.routeDetails.polyline))
        .map((r) => r.polyline),
    );
  } else {
    const origin = first(tour.stops);
    const destination = last(tour.stops);
    let stopsRequest = [
      {
        ...request,
        ...vehicleOptions,
        departureTime: `${first(tour.stops).time.departure}`,
        origin: `${origin.location.lat},${origin.location.lng}${
          stopsNameHints[0] ? `;nameHint=${stopsNameHints[0]}` : ''
        }${originSideOfStreet}`,
        destination: `${destination.location.lat},${destination.location.lng}${
          stopsNameHints[stopsNameHints.length - 1]
            ? `;nameHint=${stopsNameHints[stopsNameHints.length - 1]}`
            : ''
        }${destinationSideOfStreet}`,
        transportMode: mode,
      },
    ];

    if (size(tour.stops) > 2) {
      const slicedNameHints = stopsNameHints.slice(1, tour.stops.length - 1);
      const slicedSideOfStreet = stopsSideOfStreet.slice(1, tour.stops.length - 1);
      const params = Object.keys(stopsRequest[0]).map(
        (key) => `${key}=${getSafeValue(stopsRequest[0], key)}`,
      );
      const lengthNoVias = params.join().length + urlLength;

      const via = tour.stops.slice(1, tour.stops.length - 1).map((stop, index) => {
        const nameHint = slicedNameHints[index];
        const sideOfStreet = slicedSideOfStreet[index];
        const sideOfStreetParam = sideOfStreet
          ? `;sideOfStreetHint=${sideOfStreet.lat},${sideOfStreet.lng};matchSideOfStreet=${sideOfStreet.matchSideOfStreet}`
          : '';

        return `${stop.location.lat},${stop.location.lng}${
          nameHint ? `;nameHint=${nameHint}` : ''
        }${sideOfStreetParam}`;
      });

      const viaLength = urlCharacterLimit - lengthNoVias;
      const viaAvg = Math.round(findAverage(via.map((v) => v.length + 5)));
      const elementAmount = Math.round(viaLength / viaAvg);
      const parts = Math.round(via.length / elementAmount);
      const partCount = parts >= 1 ? parts : 1;

      const splitted = splitToNChunks([...via], partCount);
      const converted = splitted.map((split) => split.join('&via='));

      stopsRequest = converted.map((conv) => ({ ...stopsRequest[0], via: conv }));
    }

    requests.push({ request: stopsRequest, stopsRequest: true });
  }

  tour.stops.forEach((stop, i) => {
    const routing = getActivityRoutingRequest(
      request,
      stop,
      stopsOrders[i],
      stopsNameHints[i],
      stopsSideOfStreet[i],
    );
    if (routing.via) requests.push({ request: [routing] });
  });

  return { requests, stopPolylines: { polylines, mode } };
};

export const tourToRoutingRequest = (tour, tourPlanner, orders) => {
  if (!tour.typeId) return null;
  const vehicle = tourPlanner.vehicles.find((vehicleToFind) => vehicleToFind.id === tour.typeId);
  const profileName =
    vehicle?.id === tour.typeId ? vehicle.profile : tourPlanner.vehicleProfiles[0].name;
  const vehicleProfile = tourPlanner.vehicleProfiles.find(
    (profile) => profile.name === profileName,
  );
  const mode = tourPlanner && vehicleProfile.fleetType;
  const avoid = vehicleProfile.avoid;
  const options = vehicleProfile.options;
  const exclude = vehicleProfile.exclude;
  const traffic = tourPlanner.traffic;

  const avoidOptions = avoid && getAvoidOptions(avoid);
  const excludeOptions = exclude && getExcludeOptions(exclude);
  const vehicleOptions = options && getVehicleOptions(options);

  const request = {
    return: 'polyline',
    ...avoidOptions,
    ...excludeOptions,
    ...(traffic && { traffic }),
  };

  return createRoutingRequests(request, vehicleOptions, tour, mode, orders);
};

export const createFeaturesFromRoutingResponseLocations = (sections, tourId) => {
  return sections.reduce((acc, section, index) => {
    const place = section.arrival.place;
    if (place.originalLocation) {
      acc.push({
        geometry: {
          coordinates: [
            [place.location.lng, place.location.lat],
            [place.originalLocation.lng, place.originalLocation.lat],
          ],
          type: 'LineString',
        },
        type: 'Feature',
        properties: {
          isOriginalLocationLine: true,
          routeId: tourId,
          fromStop: index,
          toStop: index + 1,
        },
      });
    }
    return acc;
  }, []);
};

export const RoutingResponseToSolverFormat = ({
  payload: { routingResponse, stopPolylines, tourId, solutionId },
}) => {
  const responses = [];
  if (stopPolylines) responses.push({ ...stopPolylines });

  const originalLocation = [];
  let polylineCount = 0;

  if (routingResponse && routingResponse[0] && routingResponse[0].response) {
    responses.push(
      ...routingResponse.map(({ response, stopsRequest }) => {
        if (!response || !response.routes || !response.routes[0]) return null;
        const sections = response.routes[0].sections || [];
        const mode = sections[0].transport.mode;

        originalLocation.push(...createFeaturesFromRoutingResponseLocations(sections, tourId));

        const polylines = sections.map((s) => decode(s.polyline)).map((r) => r.polyline);

        polylineCount += polylines.flat(1).length;

        return { polylines, mode, stopsRequest };
      }),
    );
  }

  if (responses.length === 0 || responses.every((response) => response === null)) return null;

  return {
    geo: getGeoJSONfromRoutingResponse(responses, tourId),
    originalLocation,
    tourId,
    solutionId,
    polylineCount,
  };
};
