import React, { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { camelCase, map } from 'lodash';
import { isMobileDevice } from 'utils/helpers';
import { getLastValidIteration } from 'utils/SolutionHelpers';
import Stop from './Stop';
import { isStopArrival } from '../../../utils/OrdersHelpers';
import { getSafeValue } from '../../../utils/security';

const { primaryTextColor, primaryBorderColor } = colors;
const { small } = fontSize;

const StyledGridContainer = styled.div(({ smaller }) => ({
  width: '100%',
  height: smaller ? 'calc(100% - 17rem)' : 'calc(100% - 14rem)',
  overflowY: 'auto',
  position: 'absolute',
  top: smaller ? '18.5rem' : '14rem',
  marginTop: '1rem',
}));

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 4rem 2.7fr 0.8fr 1.3fr 0.3fr 0.3fr;

  div:nth-last-of-type(-n + 6) {
    border-bottom: none;
  }
  div {
    vertical-align: text-bottom;
  }

  @media screen and (max-width: 850px) {
    grid-template-columns: 3.2rem 2fr 0.6fr 1fr 0.3fr 0.1fr;
  }
`;

const StyledHeader = styled.div`
  text-align: ${(props) => (props.left ? 'left' : 'center')};

  & > span {
    text-transform: uppercase;
    font-size: ${small};
    color: ${primaryTextColor};
  }
`;

const StyledHeaderGrid = styled.div(({ smaller }) => ({
  display: 'grid',
  position: 'fixed',
  top: smaller ? '18.5rem' : '14rem',
  gridTemplateColumns: '0.32fr 0.7fr 0.1fr 0.5fr 0.2fr',
  width: '100%',
  overflow: 'hidden',
  borderBottom: `1px solid ${primaryBorderColor}`,
}));

const Stops = ({
  stops,
  request,
  tourPlanner,
  user,
  demandLabel,
  isDemandVisible,
  tourId,
  typeId,
  smaller,
  translations: {
    tourSolution: { stopHeaders },
  },
}) => {
  const [activeStop, setActiveStop] = useState(1);
  const gridContentRef = useRef(null);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const filtered = getLastValidIteration(solution.requests[solution.show]);
  const unassigned =
    filtered?.response?.unassigned && filtered.response.unassigned.map((item) => item.jobId);

  useEffect(() => {
    gridContentRef.current.scrollTo(0, 0);
  }, [stops]);

  const tableHeader = useMemo(
    () => (
      <>
        {map(stopHeaders.concat(''), (header, index) => (
          <StyledHeader left={index === 1} key={camelCase(`${header}-${index}`)}>
            <span>{index === 2 ? demandLabel : header}</span>
          </StyledHeader>
        ))}
      </>
    ),
    [stopHeaders, demandLabel],
  );

  const onStopClick = useCallback(
    (index) => {
      if (activeStop === index) {
        setActiveStop(-1);
      } else {
        setActiveStop(index);
      }
    },
    [setActiveStop, activeStop],
  );

  return (
    <>
      <StyledGridContainer data-test-id="tourDetails" ref={gridContentRef} smaller={smaller}>
        <StyledHeaderGrid id={tourId} isMobile={isMobileDevice()} isHeader smaller={smaller}>
          {tableHeader}
        </StyledHeaderGrid>
        <StyledGrid key="stops-grid">
          {stops.map((stop, index) => (
            <Stop
              onStopClick={onStopClick}
              index={index}
              key={`stop-${index}`}
              stop={stop}
              request={request}
              prevStop={getSafeValue(stops, index - 1)}
              isDepot={index === 0}
              isStopActive={index === activeStop}
              isReturnLocation={
                index === stops.length - 1 &&
                (!tourPlanner ||
                  tourPlanner.returnLocation.value !== null ||
                  (tourPlanner.fileType === 'json' && isStopArrival(stop)))
              }
              isLastStop={index === stops.length - 1}
              tourStartDate={stops[0].time.arrival}
              tourPlanner={tourPlanner}
              user={user}
              demandLabel={demandLabel}
              isDemandVisible={isDemandVisible}
              tourId={tourId}
              typeId={typeId}
              unassigned={unassigned}
            />
          ))}
        </StyledGrid>
      </StyledGridContainer>
    </>
  );
};

export default withTranslation(Stops);
