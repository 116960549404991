export function isCollinear(p1, p2, p3) {
  // Check if three points are collinear using the cross product
  return (p2[1] - p1[1]) * (p3[0] - p1[0]) === (p3[1] - p1[1]) * (p2[0] - p1[0]);
}

export function getOverlap(p1, p2, p3, p4) {
  // Ensure p1, p2 is sorted
  if (p1[0] > p2[0] || (p1[0] === p2[0] && p1[1] > p2[1])) [p1, p2] = [p2, p1];
  if (p3[0] > p4[0] || (p3[0] === p4[0] && p3[1] > p4[1])) [p3, p4] = [p4, p3];

  // Check if projections overlap
  const startX = Math.max(p1[0], p3[0]);
  const endX = Math.min(p2[0], p4[0]);

  const startY = Math.max(p1[1], p3[1]);
  const endY = Math.min(p2[1], p4[1]);

  if (startX <= endX && startY <= endY) {
    return { start: [startX, startY], end: [endX, endY] };
  }

  return null; // No overlap
}

export function findIntersection(p1, p2, p3, p4) {
  const det = (p2[0] - p1[0]) * (p4[1] - p3[1]) - (p2[1] - p1[1]) * (p4[0] - p3[0]);
  if (det === 0) return null; // Lines are parallel or collinear

  const t = ((p3[0] - p1[0]) * (p4[1] - p3[1]) - (p3[1] - p1[1]) * (p4[0] - p3[0])) / det;
  const u = ((p3[0] - p1[0]) * (p2[1] - p1[1]) - (p3[1] - p1[1]) * (p2[0] - p1[0])) / det;

  if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
    // Calculate the intersection point
    const x = p1[0] + t * (p2[0] - p1[0]);
    const y = p1[1] + t * (p2[1] - p1[1]);
    return [x, y];
  }

  return null; // No intersection
}

export function findPolylineIntersections(polyline1, polyline2) {
  const intersections = [];
  for (let i = 0; i < polyline1.length - 1; i++) {
    for (let j = 0; j < polyline2.length - 1; j++) {
      const [p1, p2] = [polyline1[i], polyline1[i + 1]];
      const [p3, p4] = [polyline2[j], polyline2[j + 1]];

      // Ignore reversed overlaps (A->B and B->A)
      if (
        (p1[0] === p4[0] && p1[1] === p4[1] && p2[0] === p3[0] && p2[1] === p3[1]) ||
        (p1[0] === p3[0] && p1[1] === p3[1] && p2[0] === p4[0] && p2[1] === p4[1])
      ) {
        // These are identical or reversed segments, no need to treat them as intersections
        return intersections;
      }

      const intersection = findIntersection(p1, p2, p3, p4);
      if (intersection) intersections.push(intersection);
    }
  }
  return intersections;
}

export function findPolylineOverlaps(polyline1, polyline2) {
  const overlaps = [];
  for (let i = 0; i < polyline1.length - 1; i++) {
    for (let j = 0; j < polyline2.length - 1; j++) {
      const [p1, p2] = [polyline1[i], polyline1[i + 1]];
      const [p3, p4] = [polyline2[j], polyline2[j + 1]];

      // Check if segments are collinear
      if (isCollinear(p1, p2, p3) && isCollinear(p1, p2, p4)) {
        const overlap = getOverlap(p1, p2, p3, p4);
        if (overlap) overlaps.push([overlap.start, overlap.end]);
      }
    }
  }
  return overlaps;
}

export function findIntersectingAndOverlappingPolylines(polylines) {
  const results = {
    intersections: [],
    overlaps: [],
  };

  for (let i = 0; i < polylines.length; i++) {
    for (let j = i + 1; j < polylines.length; j++) {
      const polyline1 = polylines[i];
      const polyline2 = polylines[j];

      const intersections = findPolylineIntersections(polyline1, polyline2);
      const overlaps = findPolylineOverlaps(polyline1, polyline2);

      if (intersections.length > 0) {
        results.intersections.push(...intersections);
      }

      if (overlaps.length > 0) {
        results.overlaps.push(...overlaps);
      }
    }
  }

  return results;
}
