import React, { useCallback } from 'react';
import styled from '@emotion/styled';

import withTranslation from '../../../hoc/withTranslation';

const StyledHeader = styled.h2({ fontSize: '18px', margin: '10px 0' });
const StyledTable = styled.table({
  borderCollapse: 'collapse',
  width: '70%',
  margin: '0 auto',
  border: '1px solid #333',
});
const StyledTh = styled.th({
  border: '1px solid #333',
  padding: '8px',
  backgroundColor: '#f2f2f2',
});

const TourStatistics = ({
  user,
  translations: {
    tourStatistics: { totalIntersections, totalOverlaps, tourHeader, tourMetric, tourCount },
  },
}) => {
  const stats = useCallback(
    [
      { label: totalIntersections, count: user.intersectionCount || 0 },
      { label: totalOverlaps, count: user.overlapCount || 0 },
    ],
    [user.intersectionCount, user.overlapCount],
  );

  return (
    <div style={{ textAlign: 'center' }}>
      <StyledHeader style={{ fontSize: '18px', margin: '10px 0' }}>{tourHeader}</StyledHeader>
      <StyledTable>
        <thead>
          <tr>
            <StyledTh>{tourMetric}</StyledTh>
            <StyledTh>{tourCount}</StyledTh>
          </tr>
        </thead>
        <tbody>
          {stats.map((stat, key) => (
            <tr key={key}>
              <td style={{ border: '1px solid #333', padding: '8px' }}>{stat.label}</td>
              <td
                style={{
                  border: '1px solid #333',
                  padding: '8px',
                  textAlign: 'center',
                }}
              >
                {stat.count}
              </td>
            </tr>
          ))}
        </tbody>
      </StyledTable>
    </div>
  );
};

export default withTranslation(TourStatistics);
