import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MapContainer from 'components/Map/MapContainer';
import { getLocationAddress } from 'utils/GeoCoder';
import { isEmpty } from 'lodash';
import { ErrorBoundary } from 'react-error-boundary';
import TourPanel from 'components/FloatingPanels/Tours/TourPanel';
import QRCodePanel from 'components/FloatingPanels/QRCode/QRCodePanel';
import PanelAction from 'components/FloatingPanels/PanelAction/PanelAction';
import Footer from './components/Map/Footer';
import Menu from './components/FloatingPanels/Menu/Menu';
import { TIMEOUT_VALUES, deleteSolution, getOAuth, setError, setUserParam } from './actions';
import 'leaflet/dist/leaflet.css';
import getUserLocation from './utils/getUserLocation';
import TopBar from './components/TopBar/TopBar';
import { isMobileDevice, isProdEnv } from './utils/helpers';
import FeedbackContainer from './components/FloatingPanels/Feedback/FeedbackContainer';
import SolverConfigurationContainer from './components/FloatingPanels/SolverConfiguration/SolverConfigurationContainer';
import ApiKeyPanel from './components/FloatingPanels/ApiKey/ApiKeyPanel';
import withTranslation from './hoc/withTranslation';
import DevConsole from './components/FloatingPanels/DevConsole/DevConsole';
import MapSettingsPanel from './components/FloatingPanels/MapSettings/MapSettingsPanel';

const isProd = isProdEnv();

const DAY_COUNT = 3;
const THREE_DAYS = 1000 * 60 * 60 * 24 * DAY_COUNT;

const App = ({ translations }) => {
  const dispatch = useDispatch();
  const oAuth = useSelector(({ oAuth: stateOAuth }) => stateOAuth);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const errorState = useSelector((state) => state.error);
  const handleGetOAuth = useCallback((data) => dispatch(getOAuth(data)), [dispatch]);
  const handleSetUserLocation = useCallback(
    (location) => dispatch(setUserParam({ location })),
    [dispatch],
  );
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const handleDeleteSolution = useCallback((idx) => dispatch(deleteSolution(idx)), [dispatch]);

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showMapMobile, setShowMapMobile] = useState(false);
  const [isMenuActive, setIsMenuActive] = useState(true);
  const isMobile = isMobileDevice();
  const [isTourPanelOpen, setIsTourPanelOpen] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [wizardShown, setWizardShown] = useState(true);

  const requestDates =
    solution.requests.map((r, idx) => {
      return { date: Date.parse(r.date), idx };
    }) ?? [];

  useEffect(() => {
    const currentTimeAsMs = Date.now();

    if (requestDates) {
      const indexes = [];
      requestDates.forEach((rd) => {
        if (currentTimeAsMs >= rd.date + THREE_DAYS) {
          indexes.push(rd.idx);
        }
      });
      if (!isEmpty(indexes)) handleDeleteSolution({ index: indexes });
    }
  }, [handleDeleteSolution]);

  const handleResize = useCallback(() => {
    setIsMenuActive(false);
    setTimeout(() => setIsMenuActive(true), TIMEOUT_VALUES.HIGH);
  }, [setIsMenuActive]);

  useEffect(() => {
    handleGetOAuth();

    window.addEventListener('resize', handleResize);
  }, [handleGetOAuth, handleResize]);

  useEffect(() => {
    getUserLocation().then(
      (loc) => {
        if (loc && loc.coords) {
          handleSetUserLocation(loc.coords);
          if (oAuth) {
            const location = { lat: loc.coords.latitude, lng: loc.coords.longitude };
            getLocationAddress(location, oAuth, user).then((item) => {
              if (item.address)
                handleSetUserParam({
                  politicalView: item.address.countryCode.toUpperCase(),
                });
            });
          }
        }
      },
      () => {},
    );
  }, [handleSetUserLocation, oAuth, handleSetUserParam]);

  const onToggleMobileView = useCallback(() => {
    setShowMapMobile(!showMapMobile);
  }, [setShowMapMobile, showMapMobile]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  const toggleTourPanel = useCallback(() => {
    setIsTourPanelOpen(!isTourPanelOpen);
  }, [isTourPanelOpen]);

  const handleError = useCallback(
    (error) => {
      if (retryCount > 3) return;
      setRetryCount(retryCount + 1);
      if (isProd) dispatch(setError(translations.error.unknownError));
      else dispatch(setError(`${error.name}\n${error.message}\n${error.stack}`));
    },
    [setRetryCount, retryCount, dispatch, translations.error.unknownError],
  );

  return (
    <>
      <ErrorBoundary fallback={null} onError={handleError} resetKeys={[retryCount]}>
        <TopBar
          onClickMenu={toggleMenu}
          onToggleMobileView={onToggleMobileView}
          isVisible={!showMapMobile}
          onClickTourPanel={toggleTourPanel}
          isTourPanelOpen={isTourPanelOpen}
          solution={solution}
        />
        <MapContainer
          user={user}
          oAuth={oAuth}
          showMapMobile={showMapMobile}
          solution={solution}
          wizardShown={wizardShown}
        />
        {!isMobile && <Footer />}
        <FeedbackContainer wizardShown={wizardShown} />
        <SolverConfigurationContainer user={user} wizardShown={wizardShown} />
        <MapSettingsPanel user={user} wizardShown={wizardShown} />
        <PanelAction setWizardShown={setWizardShown} wizardShown={wizardShown} />
        <QRCodePanel user={user} />
        {!isMobile && <DevConsole oAuth={oAuth} errorState={errorState} solution={solution} />}
        {isProd && <ApiKeyPanel user={user} setIsMenuOpen={(value) => setIsMenuOpen(value)} />}
        {isMenuActive && (
          <Menu user={user} isOpen={isMenuOpen} onClickClose={toggleMenu} solution={solution} />
        )}
        <TourPanel solution={solution} user={user} oAuth={oAuth} isOpen={isTourPanelOpen} />
      </ErrorBoundary>
    </>
  );
};

export default withTranslation(App);
