import React, { useState, useCallback, useEffect } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { clearLocalStorage } from 'utils/localStorageHelpers';
import { StyledIcon } from 'components/Wizard/Global/WizardStyled';
import { getSplunkAWSLogUrl } from 'utils/SolutionHelpers';
import { useDispatch } from 'react-redux';
import { Swipeable } from '../Form/Swipeable';
import HereLogo from '../Presentation/HereLogo';
import { fontSize, colors } from '../../global/variables';
import { isMobileDevice, isProdEnv } from '../../utils/helpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../utils/amplitude';
import menu from '../../global/img/menu.svg';
import mapHandler from '../../global/img/map-handler.svg';
import chevronDown from '../../global/img/chevron-down.svg';
import chevronRight from '../../global/img/chevron-right.svg';
import clearIcon from '../../global/img/clear-icon.svg';
import splunkLogo from '../../global/img/splunk_logo.svg';

const { medium } = fontSize;
const { black } = colors;

const headerHeight = '3rem';

const StyledTopBar = styled.div(() => ({
  display: 'flex',
  height: headerHeight,
  alignItems: 'center',
  justifyContent: 'flex-end',
  '@media screen and (max-width: 850px)': {
    flexDirection: 'row-reverse',
  },
}));

const StyledTopBarContainer = styled.div(({ showTransparent, isVisible }) => ({
  position: 'fixed',
  top: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: headerHeight,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  backgroundColor: 'rgba(250, 250, 250, 0.1)',
  boxSizing: 'border-box',
  zIndex: '10000',
  paddingLeft: '2rem',
  boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
  borderRadius: '0',
  textAlign: 'left',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  div: {
    lineHeight: headerHeight,
    verticalAlign: 'middle',
    transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  },
  '@media screen and (max-width: 850px)': {
    textAlign: 'center',
    justifyContent: 'center',
    boxShadow: 'none',
    backgroundColor: showTransparent ? 'transparent' : 'rgba(250, 250, 250, 1)',
    backdropFilter: 'blur(2px)',
    top: isVisible ? '0' : 'calc(100% - 2rem)',
    div: {
      position: 'absolute',
      top: !isVisible ? '-2rem' : '0.5rem',
      transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
    },
  },
}));

const StyledTopBarTitle = styled.div({
  marginLeft: '1.1rem',
  fontWeight: 'bold',
  color: black,
  opacity: '0.8',
  fontSize: medium,
  display: 'inline-block',
  '@media screen and (max-width: 850px)': {
    display: 'none',
  },
});

export const StyledToggleButton = styled.button(({ icon }) => ({
  display: 'flex',
  top: '0.7rem',
  backgroundColor: 'transparent',
  backgroundImage: `url(${icon})`,
  backgroundRepeat: 'no-repeat',
  border: 'none',
  opacity: 1,
  width: '1.5rem',
  height: '1.5rem',
  cursor: 'pointer',
  zIndex: 10002,
  transition: 'all 0.1s ease-in-out',
  '@media screen and (max-width: 850px)': {
    marginLeft: '0.7rem',
  },
  '@media screen and (min-width: 851px)': {
    marginRight: '1.4rem',
  },
}));

const StyledBarHandle = styled.div(({ isVisible }) => ({
  width: '3rem',
  height: '1rem',
  marginTop: isVisible ? '-1.6rem' : '2.4rem',
  backgroundColor: '#f8f9f8',
  borderRadius: '0.2rem',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  cursor: 'pointer',
  backgroundImage: `url(${mapHandler})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  '@media screen and (min-width: 851px)': {
    display: 'none',
    width: 0,
    height: 0,
  },
}));

const StyledToggleButtons = styled.div(() => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: 'auto',
}));

const StyledLink = styled.a({
  color: '#01b6b2',
  textDecorationLine: 'none',
  padding: '0 0.4rem 0 1rem',
  fontSize: '13px',
  ':hover': {
    color: '#00a39f',
    textDecorationLine: 'underline',
  },
});

const StyledSpan = styled.span(({ isFilename }) => ({
  display: 'inline-block',
  width: isFilename ? '6rem' : '5rem',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginTop: '0.4rem',
}));

const TopBar = ({
  onClickMenu,
  onToggleMobileView,
  isVisible,
  onClickTourPanel,
  isTourPanelOpen,
  solution,
  translations: {
    global: { awsLogsTrans },
  },
}) => {
  const dispatch = useDispatch();

  const [showMapMobile, setShowMapMobile] = useState(false);
  const request = solution.requests[solution.show];

  const onClickBar = useCallback(() => {
    if (!isMobileDevice()) return;

    const component = showMapMobile ? 'map' : 'panel';
    AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component });
    onToggleMobileView();
    setShowMapMobile(!showMapMobile);
  }, [onToggleMobileView, setShowMapMobile, showMapMobile]);

  const onClickMenuButton = useCallback(
    (evt) => {
      evt.preventDefault();
      evt.stopPropagation();
      AmplitudeService.log(AMPLITUDE_EVENTS.TOP_BAR_CLICK, { component: 'Menu' });
      onClickMenu();
    },
    [onClickMenu],
  );

  useEffect(() => {
    getSplunkAWSLogUrl(dispatch, request, isProdEnv(), solution.show);
  }, [request, dispatch, solution.show]);

  return (
    <StyledTopBar>
      <Swipeable onSwipedUp={onClickBar} onSwipedDown={onClickBar}>
        <StyledTopBarContainer
          onClick={onClickBar}
          showTransparent={!showMapMobile}
          isVisible={isVisible}
        >
          <HereLogo data-test-id="HereLogo" />
          <StyledTopBarTitle data-test-id="HereTitle">HERE Tour Planning</StyledTopBarTitle>
          {request?.splunkLogUrl && (
            <>
              <StyledLink
                href={request.splunkLogUrl}
                target="_blank"
                rel="noopener noreferrer"
                title={request.splunkLogUrl}
              >
                <StyledSpan title={request.splunkLogUrl}>{`${awsLogsTrans} `}</StyledSpan>
                <StyledSpan isFilename title={request.name}>
                  {request.name}
                </StyledSpan>
              </StyledLink>
              <StyledIcon iconUrl={splunkLogo} huge logo />
            </>
          )}
          <StyledBarHandle isVisible={!isVisible} />
          <StyledToggleButtons>
            <StyledToggleButton
              data-test-id="clearLocalStorage"
              type="button"
              onClick={clearLocalStorage}
              icon={clearIcon}
              title="clear local storage"
            />
            <StyledToggleButton
              data-test-id="toggleArrow"
              type="button"
              onClick={onClickTourPanel}
              icon={isTourPanelOpen ? chevronDown : chevronRight}
            />
            <StyledToggleButton
              type="button"
              onClick={onClickMenuButton}
              icon={menu}
              data-testid="menuBtn"
            />
          </StyledToggleButtons>
        </StyledTopBarContainer>
      </Swipeable>
    </StyledTopBar>
  );
};

export default withTranslation(TopBar);
