import React, { useCallback, useState } from 'react';
import { APP_MODES, getAppMode } from 'utils/urlHelpers';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';
import withTranslation from '../../../hoc/withTranslation';

const appMode = getAppMode();

const FeatureFlagSelector = ({ disabled, translations }) => {
  const {
    menu: { disabledRequestTypeInfo },
  } = translations;
  const filterDefFF = appMode.filter((m) => m !== APP_MODES.NORMAL);
  const defaultValue = filterDefFF.length === 0 ? [APP_MODES.NONE] : filterDefFF;
  const [selectedFF, setSelectedFF] = useState(defaultValue);
  const [showMultiple, setShowMultiple] = useState(false);
  const ffLimit = selectedFF?.length >= 3;
  const selectedFFValues = selectedFF?.join(',');
  const modeValues = Object.values(APP_MODES).filter((mode) => mode !== APP_MODES.NORMAL);
  const selectTitle = disabled
    ? disabledRequestTypeInfo
    : showMultiple
    ? selectedFF
    : selectedFFValues;

  const onChooseFFs = useCallback(
    (e) => {
      const value = e.target.value;
      const isNone = value === APP_MODES.NONE;

      if (!isNone) setSelectedFF((prev) => prev?.filter((p) => p !== APP_MODES.NONE));
      if (isNone) setSelectedFF();
      else if (selectedFF?.includes(value)) {
        setSelectedFF((prev) => prev.filter((v) => v !== value));
      } else {
        setSelectedFF((prev) => (prev ? [...prev, value] : [value]));
      }
    },
    [selectedFF],
  );

  const onApplyFlag = useCallback(() => {
    const location = window.location;
    if (selectedFF) {
      let featureFlag = selectedFF;
      if (selectedFF?.includes(APP_MODES.NONE) && selectedFF.length > 1) {
        featureFlag = selectedFF.filter((ff) => ff !== APP_MODES.NONE);
      }
      location.href = `${location.origin}/?m=${featureFlag}`;
    } else location.href = location.origin;
  }, [selectedFF]);

  return (
    <StyledSelectInput
      data-testid="ffDropdown"
      contrast
      sizeL
      onChange={onChooseFFs}
      value={showMultiple ? selectedFF : appMode.join(',')}
      onFocus={() => setShowMultiple(true)}
      onBlur={onApplyFlag}
      disabled={disabled}
      title={selectTitle}
      multiple={showMultiple}
    >
      {showMultiple ? (
        <>
          {modeValues.map((mode, idx) => (
            <option
              key={idx}
              value={mode}
              disabled={!selectedFF?.includes(mode) && ffLimit && mode !== APP_MODES.NONE}
            >
              {mode}
            </option>
          ))}
        </>
      ) : (
        <option value={selectedFFValues}>{selectedFFValues}</option>
      )}
    </StyledSelectInput>
  );
};

export default withTranslation(FeatureFlagSelector);
