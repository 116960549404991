import { GeoJSON } from 'react-leaflet';
import React, { useCallback, useEffect } from 'react';
import { setUserParam } from 'actions';
import { useDispatch } from 'react-redux';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import { getStyle } from '../../../utils/map/MapUtils';

const UndesiredMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
}) => {
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const intersectionCount = routingRoutes.features.filter(
    (f) => f?.properties.isUndesired && f.geometry.type === 'Point',
  )[0]?.properties.intersectionCount;
  const overlapCount = routingRoutes.features.filter(
    (f) => f?.properties.isUndesired && f.geometry.type === 'Point',
  )[0]?.properties.overlapCount;

  const filterMarkersClusterFeatures = useCallback((feature) => {
    return feature.properties.isUndesired && feature.geometry.type === 'Point';
  }, []);
  const clusteredData = useCallback(() => {
    return routingRoutes?.features.filter(
      (f) => f.geometry.type === 'Point' && f.properties.isUndesired,
    );
  }, [routingRoutes]);

  useEffect(() => {
    if (!user?.intersectionCount && intersectionCount) handleSetUserParam({ intersectionCount });
    if (!user?.overlapCount && overlapCount) handleSetUserParam({ overlapCount });
  }, [
    handleSetUserParam,
    user?.intersectionCount,
    user?.overlapCount,
    intersectionCount,
    overlapCount,
  ]);

  return (
    <GeoJSON
      key={`${mapDataKey(mapData, user, display, routingRoutes, highlightStop)}-undesired-marker`}
      data={clusteredData()}
      pointToLayer={makeMapMarkers}
      style={getStyle}
      filter={filterMarkersClusterFeatures}
    />
  );
};

export default UndesiredMarkers;
