import React from 'react';
import styled from '@emotion/styled';
import { isBreak, isPudo, isRecharge, isReload } from '../../../utils/SolutionHelpers';

const StyledStopIcon = styled.div(({ isReturnLocation }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: !isReturnLocation ? 'flex-start' : 'center',
  height: '100%',
  paddingTop: !isReturnLocation ? '8px' : '0px',
  zIndex: '1000',
  gridArea: 'stopLine',
}));

const DepotIcon = ({ textColor }) => {
  return (
    <svg
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons-24-/-Solid-/-Others-/-home_" transform="translate(9.000000, 8.000000)">
          <rect id="Rectangle" x="0" y="0" width="14" height="14" />
          <path
            d="M6.51583333,1.37666667 C6.6431667,1.24624888 6.81772936,1.172716 7,1.172716 C7.18227064,1.172716 7.3568333,1.24624888 7.48416667,1.37666667 L12.8333333,6.41666667 L12.8333333,12.8333333 L8.75,12.8333333 L8.75,8.75 L5.25,8.75 L5.25,12.8333333 L1.16666667,12.8333333 L1.16666667,6.41666667 L6.51583333,1.37666667 Z"
            id="---↳-🌈-Color"
            fillOpacity="0.8"
            fill={textColor}
            fillRule="evenodd"
          />
        </g>
      </g>
    </svg>
  );
};

const Text = ({ text, textColor }) => {
  return (
    <text
      x="50%"
      y="63%"
      textAnchor="middle"
      fontSize="12px"
      fontFamily="FiraGO"
      fontWeight="700"
      fill={textColor ?? 'white'}
    >
      {text}
    </text>
  );
};

const ReturnLocationIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0" maskUnits="userSpaceOnUse" x="7" y="7" width="18" height="18">
        <circle cx="16" cy="16.0001" r="8.25252" fill="white" />
      </mask>
      <g mask="url(#mask0)">
        <rect x="-6" y="-6" width="44" height="44" fill="#FFFFFF" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32.5 -2.33337H-0.500041C-1.51256 -2.33337 -2.33337 -1.51256 -2.33337 -0.500041V32.5C-2.33337 33.5125 -1.51256 34.3333 -0.500041 34.3333H32.5C33.5125 34.3333 34.3333 33.5125 34.3333 32.5V-0.500041C34.3333 -1.51256 33.5125 -2.33337 32.5 -2.33337ZM30.6666 8.66664H27V12.3333H30.6666V16H27V19.6666H30.6666V23.3333H27V27H30.6666V30.6666H27V27H23.3333V30.6666H19.6666V27H16V30.6666H12.3333V27H8.66664V30.6666H4.99998V27H1.33331V23.3333H4.99998V19.6666H1.33331V16H4.99998V12.3333H1.33331V8.66664H4.99998V4.99998H1.33331V1.33331H4.99998V4.99998H8.66664V1.33331H12.3333V4.99998H16V1.33331H19.6666V4.99998H23.3333V1.33331H27V4.99998H30.6666V8.66664ZM4.99994 23.3333V27H8.66661V23.3333H4.99994ZM8.66663 19.6667V23.3334H12.3333V19.6667H8.66663ZM12.3333 15.9999V19.6666H16V15.9999H12.3333ZM15.9999 16H19.6666V12.3333H15.9999V16ZM19.6666 12.3334H23.3333V8.6667H19.6666V12.3334ZM23.3333 4.99994V8.66661H27V4.99994H23.3333ZM12.3333 23.3333V27H16V23.3333H12.3333ZM15.9999 19.6667V23.3334H19.6666V19.6667H15.9999ZM19.6666 19.6666H23.3333V15.9999H19.6666V19.6666ZM23.3333 16H27V12.3333H23.3333V16ZM19.6666 23.3333V27H23.3333V23.3333H19.6666ZM23.3333 23.3334H27V19.6667H23.3333V23.3334ZM8.66661 15.9999H4.99994V19.6666H8.66661V15.9999ZM12.3333 12.3333H8.66663V16H12.3333V12.3333ZM16 12.3334V8.6667H12.3333V12.3334H16ZM19.6666 8.66661V4.99994H15.9999V8.66661H19.6666ZM8.66661 8.6667H4.99994V12.3334H8.66661V8.6667ZM12.3333 8.66661V4.99994H8.66663V8.66661H12.3333Z"
          fill="#000A19"
          fillOpacity="0.8"
        />
      </g>
    </svg>
  );
};

const BreakIcon = ({ textColor }) => {
  return (
    <svg
      id="tdBreakIcon"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 22 32"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      y="15%"
    >
      <path d="" fill="none" stroke="#3f5787" strokeWidth="0.5" />
      <g transform="matrix(1.659827 0 0 1.533335-8.438331-6.881853)">
        <path
          d="M-1.593911,-0.967322c2.061493-.122052.953363.579795-.425621,1.150348"
          transform="matrix(.999982 0 0 1.785767 15.441892 13.717235)"
          fill={textColor}
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          d="M-0.271486,-0.679835h2.0151c.000001,1.01516-.000003,2.461452-1.439357,2.461452h-1.151485c-1.437046,0-1.437048-1.446292-1.437047-2.461454l2.012789.000002Z"
          transform="matrix(1.368168 0 0 1.90575 11.561466 12.295599)"
          fill={textColor}
          strokeWidth="0"
        />
        <path
          d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233"
          transform="translate(9.829381 8.686118)"
          fill={textColor}
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233"
          transform="translate(12.301569 8.686118)"
          fill={textColor}
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          d="M-0.016264,-1.435604c.705338,1.545116-.943336,1.545116,0,3.090233"
          transform="translate(11.073141 8.686118)"
          fill={textColor}
          stroke="#fff"
          strokeWidth="0.3"
        />
      </g>
      <path d="" fill="none" stroke="#3f5787" strokeWidth="0.5" />
    </svg>
  );
};

const ReloadIcon = ({ textColor }) => {
  return (
    <svg id="tdReloadIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 32" y="15%">
      <path
        d="M355.936917,314.118636l-1.84705,1.433685c-.971396-.795804-2.218889-1.279203-3.573865-1.279203-3.062358,0-5.579002,2.458819-5.579002,5.450375c0,2.991754,2.516846,5.394807,5.579002,5.394807c2.400659,0,4.463688-1.474144,5.243801-3.553363l-1.998863-.475825c-.534926,1.232356-1.774544,2.082501-3.244938,2.082501-1.968318,0-3.529738-1.525322-3.529738-3.448357s1.561319-3.503926,3.529738-3.503926c.730299,0,1.401329.220906,1.96091.593249l-1.828064,1.421325l5.28813.95786v-5.073464Z"
        transform="translate(-339.937-309.1183)"
        fill={textColor}
      />
    </svg>
  );
};

const PudoIcon = ({ textColor }) => {
  return (
    <svg
      id="tdPudoIcon"
      viewBox="0 0 40 40"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      y="20%"
      x="20%"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2774 2.49587C12.4684 2.11515 11.5317 2.11515 10.7226 2.49587L2.5 6.36533V15.7307C2.5 16.8928 3.17113 17.9504 4.22261 18.4452L12 22.1051L19.7774 18.4452C20.8289 17.9504 21.5 16.8928 21.5 15.7307V6.36533L13.2774 2.49587ZM11.5742 4.30551C11.8439 4.1786 12.1561 4.1786 12.4258 4.30551L19.0672 7.43089L12 10.7566L4.93279 7.43088L11.5742 4.30551ZM4.5 9.7139V15.7307C4.5 16.1181 4.72371 16.4706 5.0742 16.6355L10.875 19.3653V12.7139L4.5 9.7139ZM13.125 19.3653L18.9258 16.6355C19.2763 16.4706 19.5 16.1181 19.5 15.7307V9.71392L13.125 12.7139V19.3653Z"
        fill={textColor}
      />
    </svg>
  );
};

const RechargeIcon = ({ textColor }) => {
  return (
    <svg
      id="tdRechargeIcon"
      width="24"
      height="24"
      viewBox="0 0 30 30"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      y="20%"
      x="20%"
    >
      <path d="M12 22H10L11 13H5L11 2H13L12 11H18L12 22Z" fill={textColor} />
    </svg>
  );
};

const StopIcon = ({ color, text, isDepot, isReturnLocation, highPriority = false, stopType }) => {
  return (
    <StyledStopIcon isReturnLocation={isReturnLocation}>
      <svg
        id="tdStopIcon"
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <circle stroke={color.outer} fill={color.inner} cx="16" cy="16" r="12" />
          {highPriority && <circle cx="16" cy="16" r="8" fill="#F5BE45" />}
          {!isReturnLocation && !isDepot && (
            <Text text={text} textColor={highPriority ? '#000000' : color.text} />
          )}
          {isDepot && <DepotIcon textColor={color.text} />}
          {isReturnLocation && <ReturnLocationIcon />}
          {isReload(stopType) && <ReloadIcon textColor={color.text} />}
          {isBreak(stopType) && <BreakIcon textColor={color.text} />}
          {isPudo(stopType) && <PudoIcon textColor={color.text} />}
          {isRecharge(stopType) && <RechargeIcon textColor={color.text} />}
        </g>
      </svg>
    </StyledStopIcon>
  );
};

export default StopIcon;
