import styled from '@emotion/styled';
import Map from 'components/Map/Map';
import Modal from 'components/Presentation/Modal';
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { last } from 'lodash';
import {
  getLastValidIteration,
  getToursDataFromIteration,
  isIterationSolution,
} from 'utils/SolutionHelpers';
import { getUrlTest } from '../../utils/urlHelpers';
import Wizard from '../Wizard/Wizard';
import { isMobileDevice } from '../../utils/helpers';
import CookieNotification from '../FloatingPanels/CookieNotification';

const isTest = getUrlTest();

const StyledContainer = styled.div`
  position: relative;
`;

const MapContainer = ({ user, oAuth, showMapMobile, solution, wizardShown }) => {
  const mapData = useSelector(({ mapData: stateMapData }) => stateMapData);
  const { display } = useSelector(({ mapContainer }) => mapContainer);
  const routingTour = useSelector(({ routingTour: stateRoutingTour }) => stateRoutingTour);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const [tourData, setTourData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const initialize = useCallback(() => {
    const notices = solution.requests[solution.show]?.notices;
    const unassigned = solution.requests[solution.show]?.unassigned;
    const filtered = getLastValidIteration(solution.requests[solution.show]);

    if (!filtered && (notices || unassigned)) {
      setTourData({ tours: [], statistic: { duration: 0, distance: 0 } });
    } else {
      setTourData(getToursDataFromIteration(filtered));
    }
    setIsInitialized(true);
  }, [solution, setTourData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    initialize();
  }, [initialize]);

  useEffect(() => initialize(), [solution, initialize]);

  const lastIteration = solution && last(solution.requests[solution.show].iterations);
  const isSolution = isIterationSolution(lastIteration);
  const data = isSolution ? lastIteration.geoJSON : mapData;
  const showWizard = isMobileDevice() ? !showMapMobile : true;
  const showMapCanvas = !!oAuth;

  return (
    <StyledContainer>
      {isInitialized && (
        <Modal isVisible={showWizard} wizardShown={wizardShown}>
          <Wizard
            user={user}
            oAuth={oAuth}
            tourData={tourData}
            tourPlanner={tourPlanner}
            display={display}
            mapData={mapData}
          />
        </Modal>
      )}
      {showMapCanvas && (
        <Map
          isSolution={isSolution}
          display={display}
          mapData={data}
          changes={[]}
          routingData={routingTour}
          tourData={tourData}
          user={user}
          oAuth={oAuth}
          tourPlanner={tourPlanner}
          solutionId={solution.show}
        />
      )}
      {!isTest && user.cookies.display && <CookieNotification user={user} />}
    </StyledContainer>
  );
};

export default MapContainer;
