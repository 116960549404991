import React, { useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import chevronRight from 'global/img/chevron-right.svg';
import chevronLeft from 'global/img/chevron-left.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setUserParam } from 'actions';
import { StyledAccessContainer } from '../StyledComponents';

const PanelAction = ({
  translations: {
    mapSettingsPanel: { wizardShownTitle, wizardHiddenTitle },
  },
  setWizardShown,
  wizardShown,
}) => {
  const dispatch = useDispatch();

  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const usageContext = useSelector(({ usageContext: stateUsageContext }) => stateUsageContext);
  const showAccesContainer = !solution.json && ![3, 4].includes(usageContext.currentStep);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const onChangeStatus = () => {
    setWizardShown(!wizardShown);
    handleSetUserParam({ wizardShown });
  };

  return (
    <>
      <StyledAccessContainer
        data-testid="wizardShowBtn"
        id="wizard-show-entry-point"
        onClick={onChangeStatus}
        order={!showAccesContainer ? 2 : 3}
        icon={wizardShown ? chevronLeft : chevronRight}
        title={wizardShown ? wizardHiddenTitle : wizardShownTitle}
        wizardShown={wizardShown}
      />
    </>
  );
};

export default withTranslation(PanelAction);
