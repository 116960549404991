import { includes, isEmpty } from 'lodash';
import { getSafeValue } from './security';

export const PARAM_LANGUAGE = 'l';
export const PARAM_MODE = 'm';
export const PARAM_TEST = 'test';
export const PARAM_USER_SOURCE = 'cid';
export const PARAM_TOUR_INDEX = 'tourIndex';
export const PARAM_USE_PRD = 'usePrd';

export const PARAM_FILE_URL = 'file-url';
export const PARAM_FILE_HOST = 'file-host';

export const APP_MODES = {
  NONE: 'none',
  NORMAL: 'n',
  DEVELOPER: 'dev',
  WIP: 'wip',
  OCR: 'ocr',
  OCR_AWS: 'ocr-aws',
  OCR_AWS_EDIT: 'ocr-aws-edit',
  OCR_AWS_ALL_FIELDS: 'ocr-aws-all-fields',
  TERRA: 'terra',
  CUSTOM_URL: 'custom-url',
  ROUTES: 'routes',
  ROUTES_CROSSING: 'crossing',
};

export function getQueryParams(url) {
  const urlParts = url ? url.split('?') : null;
  const fullSearch = urlParts ? urlParts[1] : window.location.search.substr(1);
  const search = fullSearch ? fullSearch.split('#')[0] : null;
  if (isEmpty(search)) return {};

  const params = search.split('&');
  const pairs = params.map((v) => v.split('='));
  const values = {};
  pairs.forEach((v) => {
    const value = decodeURIComponent(v[1]);
    values[v[0]] = includes(value, ',') ? value.split(',') : value;
  });
  return values;
}

export function setUrlParam(url = window.location.origin, paramName, value) {
  if (!value) return url;
  const urlParts = url ? url.split('?') : null;
  const toAdd = urlParts[1] ? '&' : '?';
  return `${url}${toAdd}${paramName}=${encodeURIComponent(value)}`;
}

export function getUrlParam(url = window.location.href, paramName, defaultValue) {
  const params = getQueryParams(url);
  return params[paramName] || defaultValue;
}

export function getUrlLanguage(url) {
  return getUrlParam(url, PARAM_LANGUAGE);
}

export function getUrlMode(url) {
  return getUrlParam(url, PARAM_MODE, APP_MODES.NORMAL);
}

export function getAppMode(url) {
  const urlMode = getUrlMode(url);
  const keys = Object.keys(APP_MODES).filter((k) => urlMode.includes(APP_MODES[k]));
  return keys.map((key) => getSafeValue(APP_MODES, key) || APP_MODES.NORMAL);
}

export function getUrlTest(url) {
  return getUrlParam(url, PARAM_TEST);
}

export function getUrlUserSource(url) {
  return getUrlParam(url, PARAM_USER_SOURCE);
}

export function getUrlInitialTourIndex(url) {
  const value = getUrlParam(url, PARAM_TOUR_INDEX, 1);
  return parseInt(value, 10);
}

export function getUsePrdEnv(url = undefined) {
  const value = getUrlParam(url, PARAM_USE_PRD);
  return value === 'true';
}

export function getFileUrlPath(url = undefined) {
  const result = getUrlParam(url, PARAM_FILE_URL);
  if (!result) return [];
  return Array.isArray(result) ? result : [result];
}

export function getFileHost(url = undefined) {
  return getUrlParam(url, PARAM_FILE_HOST);
}
