import styled from '@emotion/styled';
import React from 'react';

const getTop = (isFull, isVisible, fromTop) => {
  if (isVisible) return isFull ? 0 : '3rem';
  return fromTop ? '-100%' : 'calc(100% - 2rem)';
};

const StyledModal = styled.div(
  ({ isFull, isDark, isVisible, fromTop, fromRight, wizardShown }) => ({
    display: wizardShown === false && 'none',
    position: 'fixed',
    left: !fromRight && '1rem',
    right: fromRight && (isVisible ? '1rem' : '-28rem'),
    top: !fromTop ? '4rem' : isVisible ? '4rem' : '-100%',
    height: 'calc(100% - 7rem)',
    backgroundColor: isDark ? 'rgba(0,0,0,0.75)' : 'rgba(255,255,255,0.95)',
    zIndex: isFull ? '10100' : '999',
    width: '26rem',
    boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
    borderRadius: '0.25rem 0.25rem 2rem 2rem',
    backdropFilter: 'blur(10px)',
    textRendering: 'geometricPrecision',
    overflow: 'hidden',
    maxHeight: '100%',
    transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
    '@media screen and (max-width: 850px)': {
      top: getTop(isFull, isVisible, fromTop),
      bottom: isFull && '0',
      left: 0,
      height: isFull ? '100%' : 'calc(100% - 3rem)',
      width: '100%',
      borderRadius: '0',
      boxShadow: 'none',
    },
  }),
);

const Modal = ({
  children,
  isFull,
  maxHeight,
  isDark,
  isVisible,
  fromTop,
  fromRight,
  wizardShown,
}) => (
  <StyledModal
    isFull={isFull}
    maxHeight={maxHeight}
    isDark={isDark}
    isVisible={isVisible}
    fromTop={fromTop}
    fromRight={fromRight}
    wizardShown={wizardShown}
  >
    {children}
  </StyledModal>
);

export default Modal;
