import { isNil, size } from 'lodash';
import { divIcon, marker } from 'leaflet';
import moment from 'moment';
import { setOrders, setSolutionJson } from 'actions';
import {
  isActivityToDisplay,
  isPointDepot,
  isPointReturnLocation,
  isRouteToDisplay,
  isUnassignedJob,
  isUndesiredPoint,
} from './MapFeatureFilters';
import { isMobileDevice } from '../helpers';
import { getSafeValue, setSafeValue } from '../security';
import {
  createDepotSvg,
  createMarkerSvg,
  createOrderUnassignedSvg,
  createTourUnselectedSvg,
  createUndesiredPointSvg,
} from '../../icons/marker';

const POSITION_TYPES = {
  FIRST: 'first',
  LAST: 'last',
};

export const getTagsRow = (jobTags) =>
  size(jobTags) > 0
    ? `<tr>
    <td>Tags:</td>
    <td>${jobTags && jobTags.join(', ')}</td>
  </tr>`
    : '';

export const getTimeWindowRows = (tw, offset, hoveredId) => {
  return (tw || [])
    .map((window) =>
      window.map((t) => {
        const id = t[2];
        if (id === hoveredId) {
          const firstDate = moment(t[0]).utc().utcOffset(offset).format('lll');
          const secondDate = moment(t[1]).utc().utcOffset(offset).format('lll');
          return `<tr><td>TW:</td><td>${firstDate} - ${secondDate}</td></tr>`;
        }
        return null;
      }),
    )
    .join('');
};

export const createActivityTable = (
  duration,
  group,
  recharge,
  tw,
  hourOffset,
  showTWs,
  hoveredId,
  loadRow,
  position,
  terr,
  categories,
  houseKey,
) => {
  return `
  ${duration}
  ${group}
  ${recharge}
  ${showTWs && getTimeWindowRows(tw, hourOffset, hoveredId)}
  ${loadRow}
  ${position}
  ${terr}
  ${categories}
  ${houseKey}
  `;
};

export const createStopTable = (
  group,
  skills,
  categories,
  position,
  priority,
  duration,
  load,
  recharge,
  order,
) => {
  return `
    ${group}
    ${skills}
    ${categories}
    ${position}
    ${priority}
    ${duration}
    ${load}
    ${recharge}
    ${order}`;
};

export const getTitle = (
  translations,
  labels = undefined,
  typesObj = {},
  highPriority = false,
  address = undefined,
) => {
  const {
    wizard: {
      orders: { editedOrderPriorityHigh },
    },
    global: {
      breakLabel,
      reloadLabel,
      rechargeLabel,
      departureLabel,
      arrivalLabel,
      workingRestTimeLabel,
      drivingRestTimeLabel,
      pudoLabel,
    },
  } = translations;

  if (Object.keys(typesObj).length === 1) {
    if (typesObj.break) return `<div class="labels">${breakLabel}<br/></div>`;
    if (typesObj.recharge) return `<div class="labels">${rechargeLabel}<br/></div>`;
    if (typesObj.pudo) return `<div class="labels">${pudoLabel}<br/></div>`;
    if (typesObj.workingRestTime) return `<div class="labels">${workingRestTimeLabel}<br/></div>`;
    if (typesObj.drivingRestTime) return `<div class="labels">${drivingRestTimeLabel}<br/></div>`;
    if (typesObj.reload) return `<div class="labels">${reloadLabel}<br/></div>`;
    if (typesObj.departure) return `<div class="labels">${departureLabel}<br/></div>`;
    if (typesObj.arrival) return `<div class="labels">${arrivalLabel}<br/></div>`;
  }
  if (labels && highPriority && address)
    return `<div class="labels"><div class="high-priority">${editedOrderPriorityHigh}</div>${address}<br/>${labels.join(
      '<br />',
    )}</div>`;
  if (labels) return `<div class="labels">${labels.join('<br />')}</div>`;
  return '';
};

export const createTablePopup = (
  translations,
  title,
  jobTags,
  arrival,
  departure,
  territoryName,
  groupName,
  tw,
  hourOffset,
  skills = undefined,
  categories = undefined,
  position = undefined,
  priority = undefined,
  durationRow = undefined,
  demand = undefined,
  rechargeDistance = undefined,
  jobOrder,
  isPUDO,
  isSolution,
  isStopMarker,
  showDurationAndGroup,
  showTWs,
  hoveredId,
  stopTerritory,
  houseKeyId,
) => {
  const skillsRow =
    !isStopMarker && skills && skills.length > 0
      ? `<tr><td>${translations.skills}:</td><td>${skills.join(', ')}</td></tr>`
      : '';
  const categoriesRow =
    !isStopMarker && categories && categories.length > 0
      ? `<tr><td>${translations.categories}:</td><td>${categories.join(', ')}</td></tr>`
      : '';
  const positionRow =
    !isStopMarker && isPUDO && position && position.length > 0
      ? `<tr><td>${translations.position}:</td><td>${position.join(', ')}</td></tr>`
      : '';
  const priorityRow =
    !isStopMarker && !isSolution && !isPUDO && priority && priority.length > 0
      ? `<tr><td>${translations.priority}:</td><td>${priority.join(', ')}</td></tr>`
      : '';
  const loadRow =
    !isPUDO && !rechargeDistance && demand && demand.length > 0
      ? `<tr><td>${isStopMarker ? translations.load : translations.demand}:</td><td>${demand.join(
          ', ',
        )}</td></tr>`
      : '';
  const rechargeRow =
    rechargeDistance && rechargeDistance > 0
      ? `<tr><td>${translations.recharge}:</td><td>${rechargeDistance} m</td></tr>`
      : '';
  const orderRow =
    !isStopMarker && jobOrder
      ? `<tr><td>${translations.jobOrder}:</td><td>${jobOrder}</td></tr>`
      : '';
  const findHouseKey =
    houseKeyId &&
    houseKeyId.map((keyArr) => keyArr[1] === hoveredId && keyArr[0]).filter((val) => val !== false);
  const houseKeyRow =
    findHouseKey && findHouseKey.length > 0
      ? `<tr><td>${translations.houseKey}:</td><td>${findHouseKey[0]}</td></tr>`
      : '';
  const filteredTags = jobTags.filter((tag) => !isNil(tag));

  return `${title}
    <table>${!isStopMarker ? getTagsRow(filteredTags) : ''}
    <tr>
      <td>${translations.arrivalJobMarkerTrans}:&nbsp;&nbsp;</td>
      <td>${moment(arrival).utc().utcOffset(hourOffset).format('lll')}</td>
    </tr>
    <tr>
      <td>${translations.departureJobMarkerTrans}:&nbsp;&nbsp;</td>
      <td>${moment(departure).utc().utcOffset(hourOffset).format('lll')}</td>
    </tr>
    ${
      showDurationAndGroup || showTWs
        ? createActivityTable(
            durationRow,
            groupName,
            rechargeRow,
            tw,
            hourOffset,
            showTWs,
            hoveredId,
            loadRow,
            positionRow,
            territoryName || stopTerritory,
            categoriesRow,
            houseKeyRow,
          )
        : createStopTable(
            groupName,
            skillsRow,
            categoriesRow,
            positionRow,
            priorityRow,
            durationRow,
            loadRow,
            rechargeRow,
            orderRow,
          )
    }
    </table>
`;
};

export const makeDepotMarker = (
  feature,
  latlng,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  highlight,
  asyncOn,
) => {
  const {
    map: { depotMarkerTrans },
  } = translations;
  const hover = true;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const labels = feature.properties && feature.properties.labels ? feature.properties.labels : [];
  const title =
    (labels.length !== 0 && labels[0] !== '\n') || address.length !== 0
      ? `<div class="labels">${address}${labels.join('<br />')}</div>`
      : '';

  const options = {
    className: 'leaflet-marker-icon-job depot-icon',
    html: L.Util.template(createDepotSvg(highlight)),
    iconSize: [64, 64],
    iconAnchor: [32, 64],
  };

  const m = marker(latlng, {
    icon: divIcon(options),
    draggable: !isSolution && !asyncOn,
    zIndexOffset: -1000,
  }).bindPopup(`${title}${depotMarkerTrans}`, { className: 'custom-depot', autoPan: false });
  m.on('mouseover', () => {
    if (hover) m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  m.on('dragend', () => {
    onMarkerDragEnd(m, feature);
  });
  return m;
};

export const makeReturnLocationMarker = (
  feature,
  latlng,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  asyncOn,
) => {
  const {
    map: { returnLocationMarkerTrans },
  } = translations;
  const address =
    feature.properties && feature.properties.address ? `${feature.properties.address}<br/>` : '';
  const title = `<div class="labels">${address}</div>`;

  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job return-location-icon`,
      html: '<div></div>',
      iconSize: [64, 64],
      iconAnchor: [32, 64],
    }),
    draggable: !isSolution && !asyncOn,
  }).bindPopup(`${title}${returnLocationMarkerTrans}`, {
    className: 'custom-depot',
    autoPan: false,
  });

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  m.on('dragend', () => {
    onMarkerDragEnd(m, feature);
  });
  return m;
};

const getColor = (typesObj, jobMarkerColor, highlight, oneType, position = []) => {
  const colorMap = {
    pickup: jobMarkerColor.pickup,
    pudo: position.includes(POSITION_TYPES.FIRST) ? jobMarkerColor.pickup : jobMarkerColor.delivery,
  };

  const defaultColor = { inner: 'grey', outer: 'grey', text: 'white' };
  const type = Object.keys(typesObj)[0];
  const color = oneType ? colorMap[type] || jobMarkerColor.delivery : defaultColor;

  if (highlight) {
    return { outer: 'yellow', inner: color.inner, text: 'yellow' };
  }

  return color;
};

const getJobMarkerIcon = (
  name,
  jobMarkerColor,
  contentOfIcon,
  isHighPriority,
  typesObj,
  isUnassigned,
  highlight,
  highlightUnassigned,
  oneType,
  position,
  jobOrder,
) => {
  switch (name) {
    case 'unassigned-icon':
      return createOrderUnassignedSvg(10, 11, highlightUnassigned);
    case 'tour-unselected': {
      const color = getColor(typesObj, jobMarkerColor, highlight, oneType, position);
      return createTourUnselectedSvg(color?.inner, color?.outer);
    }
    case 'tour-selected': {
      const color = getColor(typesObj, jobMarkerColor, highlight, oneType, position);
      return createMarkerSvg(
        color.inner,
        color.outer,
        27,
        40,
        contentOfIcon,
        isHighPriority,
        typesObj,
        color.text,
        highlight,
        oneType,
        jobOrder,
      );
    }
    default:
      return '';
  }
};

export const makeJobMarker = (
  feature,
  latlng,
  isSelected,
  onMarkerClick,
  translations,
  hourOffset,
  highlight,
  highlightUnassigned,
  formatDuration,
  isSolution,
) => {
  const {
    properties: {
      arr_time: arrival,
      dep_time: departure,
      jobOrder,
      jobTags,
      routeId,
      types,
      tw,
      address,
      labels,
      highPriority,
      reason,
      territory,
      jobMarkerColor,
      position,
      skills,
      categories,
      priority,
      load,
    },
  } = feature;

  const {
    map: { territoryMarkerTrans },
    wizard: {
      tours: { unassignedReasonsTrans },
    },
  } = translations;

  const isUnassigned = isUnassignedJob(feature);
  const typesObj = (types || []).reduce((t, item) => {
    if (!getSafeValue(t, item)) setSafeValue(t, item, 0);
    setSafeValue(t, item, getSafeValue(t, item) + 1);
    return t;
  }, {});

  const iconSize = isUnassigned ? [7, 7] : [10, 10];
  const selectedClass = isSelected
    ? 'tour-selected'
    : isUnassigned
    ? 'unassigned-icon'
    : 'tour-unselected';
  const title = getTitle(translations, labels, typesObj, highPriority, address);
  const reasons = reason ? reason.map((r) => r.description) : null;
  const reasonLabel = reasons && `${unassignedReasonsTrans}: <br/>-&nbsp;${reasons.join('<br/>-')}`;
  const territoryName = territory
    ? `<tr><td>${territoryMarkerTrans}:</td><td>${territory.name}</td></tr>`
    : '';
  const oneType = Object.keys(typesObj).length === 1;
  const iconTypes = ['break', 'reload', 'drivingRestTime', 'workingRestTime', 'pudo', 'recharge'];
  const showJobOrder = oneType && Object.keys(typesObj).some((key) => iconTypes.includes(key));
  const isPUDO = oneType && Object.keys(typesObj).some((key) => key === 'pudo');
  const contentOfIcon = isSelected && !showJobOrder ? jobOrder : '';

  const options = {
    className: `leaflet-marker-icon-job tour-icon ${selectedClass}`,
    html: L.Util.template(
      getJobMarkerIcon(
        selectedClass,
        jobMarkerColor,
        contentOfIcon,
        highPriority,
        typesObj,
        isUnassigned,
        highlight,
        highlightUnassigned,
        oneType,
        position,
        jobOrder,
      ),
    ),
    iconSize: isSelected ? [35, 35] : iconSize,
    iconAnchor: isSelected ? [12, 35] : [5, 5],
  };

  const m = marker(latlng, {
    icon: new L.DivIcon(options),
  }).bindPopup(
    routeId === undefined
      ? `${title}<br/>${reasonLabel}`
      : createTablePopup(
          translations.map,
          title,
          jobTags,
          arrival,
          departure,
          territoryName,
          '',
          tw,
          hourOffset,
          skills,
          categories,
          position,
          priority,
          '',
          load,
          null,
          showJobOrder && jobOrder,
          isPUDO,
          isSolution,
          true,
        ),
    { className: `custom-job ${selectedClass}`, autoPan: false },
  );

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  return m;
};

export const makePlanMarker = (
  feature,
  latlng,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  asyncOn,
  dispatch,
  orders,
  solution,
) => {
  const {
    global: { pudoLabel },
    map: { typeJobDeliveryMarkerTrans, typeJobPickupMarkerTrans, territoryMarkerTrans },
    wizard: {
      orders: { editedOrderPriorityHigh },
    },
  } = translations;
  const {
    properties: { address, labels, isEditing, highPriority, isPickup, territory, isPudo },
  } = feature;

  const addressLabel = address ? `${address}<br/>` : '';
  const priorityLabel = highPriority
    ? `<div class="high-priority">${editedOrderPriorityHigh}</div>`
    : '';
  const title = labels
    ? `<div class="labels">${priorityLabel}${addressLabel}${labels.join('<br />')}</div>`
    : '';
  const basicIcon = highPriority ? 'plan-icon-high-priority' : 'plan-icon';
  const basicIconClass = isPickup ? `${basicIcon}-pickup` : basicIcon;
  const iconClass = isEditing ? 'plan-editing-icon' : basicIconClass;
  const typeString = isPudo
    ? pudoLabel
    : isPickup
    ? typeJobPickupMarkerTrans
    : typeJobDeliveryMarkerTrans;
  const territoryName = territory
    ? `<div><br/>${territoryMarkerTrans}: ${territory.name}</div>`
    : '';
  const m = marker(latlng, {
    icon: divIcon({
      className: `leaflet-marker-icon-job ${iconClass}`,
      html: '<div></div>',
      iconSize: isEditing ? [35, 35] : [24, 24],
      iconAnchor: isEditing ? [12, 35] : [12, 24],
    }),
    draggable: !isSolution && !asyncOn,
  }).bindPopup(`${title}${typeString}${territoryName}`, {
    className: 'custom-job',
    autoPan: false,
  });

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('contextmenu', () => {
    const featureId = feature.properties.id;
    const newOrders = orders.filter((o) => o.InternalID !== featureId);
    dispatch(setOrders(newOrders, false, solution.show));
    if (solution.json) dispatch(setSolutionJson({ jsonTriggerVRP: true }));
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });
  m.on('dragend', () => {
    onMarkerDragEnd(m, feature);
  });
  return m;
};

export const makeActivityMarker = (
  feature,
  latlng,
  translations,
  hourOffset,
  onMarkerClick,
  highlight,
  formatDuration,
  recharges,
) => {
  const {
    properties: {
      types,
      jobMarkerColor,
      arr_time: arrival,
      dep_time: departure,
      jobTags,
      tw,
      address,
      labels,
      highPriority,
      territory,
      stopTerritory,
      group,
      position,
      skills,
      categories,
      priority,
      load,
      distance,
      houseKeyId,
      stopIndex,
    },
  } = feature;

  const {
    map: { territoryMarkerTrans, groupMarkerTrans },
  } = translations;

  const typesObj = (types || []).reduce((t, item) => {
    if (!getSafeValue(t, item)) setSafeValue(t, item, 0);
    setSafeValue(t, item, getSafeValue(t, item) + 1);
    return t;
  }, {});

  const typesClass = typesObj.pickup ? 'tour-icon-pickup' : 'tour-icon-delivery';
  const iconTypes = ['break', 'reload', 'drivingRestTime', 'workingRestTime', 'pudo', 'recharge'];
  const oneType = Object.keys(typesObj).length === 1;
  const showDurationAndGroup =
    oneType && Object.keys(typesObj).some((key) => iconTypes.includes(key));
  const duration = moment(departure).diff(moment(arrival), 'minutes');
  const formattedDuration = duration > 0 ? formatDuration(Math.floor(duration)) : 'Unknown';
  const isPUDO = oneType && Object.keys(typesObj).some((key) => key === 'pudo');
  const isRecharge = oneType && Object.keys(typesObj).some((key) => key === 'recharge');

  const highlightColor = {
    outer: 'yellow',
    inner:
      typesClass === 'tour-icon-pickup'
        ? jobMarkerColor.pickup.inner
        : jobMarkerColor.delivery.inner,
  };

  const color = highlight
    ? highlightColor
    : typesClass === 'tour-icon-pickup'
    ? jobMarkerColor.pickup
    : jobMarkerColor.delivery;

  const options = {
    className: `leaflet-marker-icon-job tour-icon`,
    html: L.Util.template(
      createTourUnselectedSvg(color.inner, color.outer, highlight, true, stopIndex),
    ),
    iconSize: [10, 10],
    iconAnchor: [5, 9],
  };

  const title = getTitle(translations, labels, typesObj, highPriority, address);

  const territoryName = territory
    ? `<tr><td>${territoryMarkerTrans}:</td><td>${territory.name}</td></tr>`
    : '';
  const stopTerritoryName = stopTerritory
    ? `<tr><td>${territoryMarkerTrans}:</td><td>${stopTerritory?.name}</td></tr>`
    : '';
  const durationElem =
    formattedDuration !== 'Unknown'
      ? `<tr><td>${translations.map.duration}:</td><td>${formattedDuration}</td></tr>`
      : '';

  const groupName = group ? `<tr><td>${groupMarkerTrans}:</td><td>${group.name}</td></tr>` : '';
  const rechargeIdx = distance ? recharges.findIndex((recharge) => recharge === distance) : null;
  let rechargeDistance;

  if (distance >= 0 && rechargeIdx >= 0 && isRecharge) {
    if (rechargeIdx === 0) rechargeDistance = recharges[0];
    else rechargeDistance = recharges[rechargeIdx] - recharges[rechargeIdx - 1];
  }

  const m = marker(latlng, {
    icon: new L.DivIcon(options),
  }).bindPopup(
    (el) =>
      createTablePopup(
        translations.map,
        title,
        jobTags,
        arrival,
        departure,
        territoryName,
        groupName,
        tw,
        hourOffset,
        skills,
        categories,
        position,
        priority,
        durationElem,
        load,
        Math.abs(rechargeDistance),
        null,
        isPUDO,
        false,
        false,
        showDurationAndGroup,
        true,
        el.feature.properties.jobIds[0],
        stopTerritoryName,
        houseKeyId,
      ),
    { className: 'custom-job tour-selected', autoPan: false },
  );

  m.on('mouseover', () => {
    m.openPopup();
  });
  m.on('mouseout', () => {
    m.closePopup();
  });
  m.on('click', () => {
    if (isMobileDevice()) m.openPopup();
    else onMarkerClick(feature);
  });

  return m;
};

export const makeUndesiredMarker = (feature, latlng) => {
  const options = {
    className: `leaflet-marker-icon-job tour-icon`,
    html: createUndesiredPointSvg(),
    iconSize: [10, 10],
    iconAnchor: [5, 9],
    style: 'background-color: transparent;', // Make sure the background is transparent
  };

  const m = marker(latlng, {
    icon: new L.DivIcon(options),
  });

  m.on('click', () => {
    // To be discussed
    // if (isMobileDevice()) m.openPopup();
    // else onMarkerClick(feature);
  });

  return m;
};

export const makeMarker = (
  feature,
  latlng,
  display,
  onMarkerClick,
  translations,
  onMarkerDragEnd,
  isSolution,
  hourOffset,
  highlight,
  highlightUnassigned,
  asyncOn,
  dispatch,
  orders,
  solution,
  formatDuration,
  recharges,
) => {
  if (isUndesiredPoint(feature))
    return makeUndesiredMarker(
      feature,
      latlng,
      translations,
      hourOffset,
      onMarkerClick,
      highlight,
      formatDuration,
      recharges,
    );
  if (isActivityToDisplay(feature))
    return makeActivityMarker(
      feature,
      latlng,
      translations,
      hourOffset,
      onMarkerClick,
      highlight,
      formatDuration,
      recharges,
    );
  if (isPointDepot(feature))
    return makeDepotMarker(
      feature,
      latlng,
      onMarkerClick,
      translations,
      onMarkerDragEnd,
      isSolution,
      highlight,
      asyncOn,
    );
  if (isPointReturnLocation(feature))
    return makeReturnLocationMarker(
      feature,
      latlng,
      onMarkerClick,
      translations,
      onMarkerDragEnd,
      isSolution,
      asyncOn,
    );
  if (isRouteToDisplay(feature, display))
    return makeJobMarker(
      feature,
      latlng,
      true,
      onMarkerClick,
      translations,
      hourOffset,
      highlight,
      highlightUnassigned,
      formatDuration,
      isSolution,
    );
  return !display.showProblem
    ? makeJobMarker(
        feature,
        latlng,
        false,
        onMarkerClick,
        translations,
        hourOffset,
        highlight,
        highlightUnassigned,
        formatDuration,
        isSolution,
      )
    : makePlanMarker(
        feature,
        latlng,
        onMarkerClick,
        translations,
        onMarkerDragEnd,
        isSolution,
        asyncOn,
        dispatch,
        orders,
        solution,
      );
};
