import styled from '@emotion/styled';
import { getAsset } from '../../utils/helpers';
import { colors } from '../../global/variables';

const { white } = colors;

export const StyledAccessContainer = styled.div(
  {
    position: 'fixed',
    left: '28rem',
    width: '2.5rem',
    height: '2.5rem',
    borderRadius: '2rem',
    backgroundColor: white,
    cursor: 'pointer',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: '1.3rem',
    zIndex: 10099,
    boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
    transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
    animationDuration: '.8s',
    animationTimingFunction: 'ease-in-out',
    animationIterationCount: 'infinite',
    ':hover': {
      backgroundSize: '1.4rem',
    },
    '@media screen and (max-width: 850px)': {
      display: 'none',
    },
  },
  ({ order, icon, wizardShown }) => ({
    top: `${order * 3 + 4}rem`,
    backgroundImage: `url(${getAsset(icon)})`,
    left: !wizardShown && 0,
  }),
);

export const StyledAction = styled.div`
  align-self: center;
  line-height: 1;
`;
