import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import config from 'config';
import { OCEAN_THEME, colors } from 'global/variables';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import { JsonViewer, applyValue } from '@textea/json-viewer';
import {
  changeJsonOrderLocation,
  clearAll,
  convertOrderToJson,
  fetchAndProcessFiles,
  handleJsonDrop,
  integrateFileText,
  recalculateMapData,
  recalculateSolutionParams,
} from 'utils/apiFilesUploadHelpers';
import {
  setError,
  setProblemJsonParam,
  setSolutionJson,
  setSolutionParam,
  setTourParameter,
  setUserParam,
} from 'actions';
import { useCookies } from 'react-cookie';
import FileUploadArea from 'components/Wizard/Step2/FileUploadArea';
import { ToastContainer, toast } from 'react-toastify';
import entryPointIcon from '../../../global/img/dev-console.svg';
import Modal from '../../Presentation/Modal';
import {
  checkForValidTPJson,
  convertToValidJson,
  formatErrorPath,
  getAsset,
  removeAllWhitespaces,
} from '../../../utils/helpers';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import withTranslation from '../../../hoc/withTranslation';
import { StyledContrastPanel } from '../../Wizard/Global/WizardStyledContrastPanel';
import { getAppMode } from '../../../utils/urlHelpers';
import DevConsoleHeader, { METHODS } from './DevConsoleHeader';
import DevConsoleFooter from './DevConsoleFooter';
import {
  getLastValidIteration,
  hasOngoingAsyncRequest,
  removeProperties,
} from '../../../utils/SolutionHelpers';
import pasteIcon from '../../../global/img/paste.svg';
import copyIcon from '../../../global/img/copy_white.svg';

const { white, secondaryColor, primaryColor } = colors;
const appMode = getAppMode();
const {
  requestTypes: { async },
} = config;

const StyledFloatingBtnContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const StyledFloatingBtn = styled.button(({ icon, order, disabled }) => ({
  position: 'fixed',
  top: order === 2 ? '212px' : '170px',
  border: 'none',
  transition: 'background-color 0.3s ease-in-out',
  cursor: 'pointer',
  zIndex: 100,
  height: '40px',
  width: '36px',
  right: '18px',
  backgroundColor: 'transparent',
  boxShadow: '0 6px 6px rgba(0, 0, 0, 0.6)',
  backgroundImage: `url(${getAsset(icon)})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  pointerEvents: disabled && 'none',
  opacity: disabled && 0.4,
  '&:hover': {
    backgroundColor: 'rgba(256, 256, 256, 0.1)',
  },
}));

const StyledAccessContainer = styled.div({
  position: 'fixed',
  top: '12rem',
  right: '1rem',
  width: '2.5rem',
  height: '2.5rem',
  borderRadius: '2rem',
  backgroundColor: white,
  cursor: 'pointer',
  marginTop: '1rem',
  backgroundImage: `linear-gradient(
    to right,
    ${secondaryColor} 0%,
    ${primaryColor} 51%,
    ${secondaryColor} 100%
  )`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',
  backgroundSize: '100%',
  zIndex: 10099,
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  animationDuration: '.8s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite',
  div: {
    backgroundImage: `url(${getAsset(entryPointIcon)})`,
    backgroundPosition: 'center center',
    backgroundSize: '1.3rem',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100%',
    ':hover': {
      backgroundSize: '1.4rem',
    },
  },
  '@media screen and (max-width: 850px)': {
    display: 'none',
  },
});

const StyledJsonContainer = styled.div({
  height: 'calc(100% - 14.5rem)',
  overflow: 'scroll',
  padding: '1rem 1rem 0 1rem',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.3)',
  borderRight: 'none',
  backgroundColor: `rgba(255,255,255,0)`,
});

const OPTIONS = {
  PROBLEM: 'problem',
  SOLUTION: 'solution',
  INTERNAL_TOUR_PLANNER: 'tourPlanner',
  INTERNAL_USER: 'user',
  INTERNAL_ITERATIONS: 'iterations',
};

const DevConsole = ({
  translations: {
    error,
    devConsoleLabels,
    jsonViewer: {
      pasteErrors,
      successfulPaste,
      successfulCopy,
      actions: { pasteJson, copyJson },
    },
    map: { returnLocationMarkerTrans, depotMarkerTrans },
  },
  oAuth,
  solution,
  errorState,
}) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['apikey']);
  const user = useSelector(({ user: stateUser }) => stateUser);
  const orders = useSelector((state) => state.orders[solution.show]);

  const territories = useSelector(({ areas: stateAreas }) => stateAreas[solution.show].territories);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const loader = useSelector(({ loader: stateLoader }) => stateLoader);
  const pasteDisabled = loader.isLoading || hasOngoingAsyncRequest(solution.requests);

  const handleSetProblemJsonParam = useCallback(
    (param) => dispatch(setProblemJsonParam(param)),
    [dispatch],
  );

  const handleSetError = useCallback((data) => dispatch(setError(data)), [dispatch]);

  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter(parameter)),
    [dispatch],
  );
  const jsonRef = useRef();
  const oldValueRef = useRef([]);
  const [jsonObject, setJsonObject] = useState();
  const [selectedOption, setSelectedOption] = useState(OPTIONS.PROBLEM);
  const [isJsonUploadClicked, setIsJsonUploadClicked] = useState(false);
  const [showJsonPanel, setShowJsonPanel] = useState(false);
  const [isTourPanelOpen, setIsTourPanelOpen] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const fileName = solution.requests[solution.show].name;
  const fileExt = fileName ? fileName.split('.').pop() : 'json';

  const reg = /(['])(?:(?=(\\?))\2.)*?\1/g;
  const pathReg = errorState?.errorMessage?.match(reg);
  const isEditable = selectedOption === OPTIONS.PROBLEM;

  useEffect(() => {
    fetchAndProcessFiles(dispatch, oAuth, user, cookies, error, solution, setShowJsonPanel);
  }, [dispatch, oAuth, setShowJsonPanel, error]);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // eslint-disable-next-line no-use-before-define
        togglePanel('close');
      }
    },
    [showJsonPanel, user.jsonTourPanelOpen, user.jsonPanelOpen],
  );

  const togglePanel = useCallback(
    (forceAction) => {
      const action = forceAction === undefined ? (showJsonPanel ? 'close' : 'open') : forceAction;
      const info = { action };
      const newState = action === 'open';
      AmplitudeService.log(AMPLITUDE_EVENTS.DEVELOPER_CONSOLE_ACTION, info);
      setShowJsonPanel(newState);
      handleSetUserParam({ jsonPanelOpen: newState });

      if (action === 'close') {
        setShowJsonPanel(false);
        handleSetUserParam({ jsonTourPanelOpen: false, jsonPanelOpen: false });
      }
    },
    [setShowJsonPanel, showJsonPanel, handleSetUserParam],
  );

  const handleDrop = useCallback(
    (files, mode, element) => {
      if (files[0].name.endsWith('.csv')) {
        handleSetError(error.csvUploadError);
        element.current.value = '';
      } else {
        handleSetTourParameter({ ordersMode: METHODS.JSON_IMPORT });
        clearAll(dispatch, !user.hasStateMemory, false, solution.show);
        handleSetSolutionParameter({ param: { name: files[0].name } });

        handleJsonDrop(
          dispatch,
          files,
          mode,
          error,
          appMode,
          cookies,
          oAuth,
          user,
          element,
          solution.show,
          false,
          depotMarkerTrans,
          returnLocationMarkerTrans,
        );
      }
    },
    [
      error,
      cookies,
      dispatch,
      oAuth,
      user,
      handleSetError,
      handleSetSolutionParameter,
      handleSetTourParameter,
      solution.show,
      depotMarkerTrans,
      returnLocationMarkerTrans,
    ],
  );

  const onChange = useCallback(
    (path, oldValue, newValue) => {
      if (newValue === '' || Number.isNaN(newValue)) {
        handleSetError(error.emptyValue);
        return;
      }
      handleSetTourParameter({ ordersMode: 'json_import', index: solution.show });
      oldValueRef.current.push({ path, oldValue, adjustedPath: formatErrorPath(path) });
      setJsonObject((prev) =>
        removeProperties(applyValue(prev, path, removeAllWhitespaces(newValue))),
      );
      handleSetProblemJsonParam({
        path,
        value: removeAllWhitespaces(newValue),
        index: solution.show,
      });

      handleSetSolutionJson({ json: true, jsonUserChange: true, jsonPending: true });
      handleSetError();
    },
    [
      handleSetSolutionJson,
      handleSetProblemJsonParam,
      handleSetError,
      solution.show,
      error,
      handleSetTourParameter,
    ],
  );

  const onCopy = useCallback(
    (path, obj, copy) => {
      const toCopy =
        path.length === 0 || path.length === undefined
          ? JSON.stringify(jsonObject)
          : `"${path.slice(-1).pop()}": ${JSON.stringify(get(jsonObject, path))}`;

      copy(toCopy);
    },
    [jsonObject],
  );

  const onJsonCopy = useCallback(() => {
    navigator.clipboard.writeText(JSON.stringify(jsonObject));
    toast(successfulCopy, {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
    });
  }, [jsonObject, successfulCopy]);

  const onPaste = useCallback(async () => {
    let json = {};
    try {
      const text = await navigator.clipboard.readText();
      json = convertToValidJson(removeAllWhitespaces(text));
    } catch {
      handleSetError(error.incorrectJsonObject);
      return;
    }
    const isSol = !!json?.tours || !!json?.statistic;
    const jsonCheck = checkForValidTPJson(json, isSol, pasteErrors);
    if (jsonCheck) {
      handleSetError(jsonCheck);
      return;
    }
    toast(successfulPaste, {
      autoClose: 1000,
      hideProgressBar: true,
      closeButton: false,
    });
    setJsonObject(removeProperties(json));
    handleSetSolutionJson({ json: true, jsonUserChange: true, jsonPaste: true });
    handleSetTourParameter({ ordersMode: METHODS.JSON_IMPORT, index: solution.show });
  }, [
    error.incorrectJsonObject,
    handleSetError,
    handleSetSolutionJson,
    handleSetTourParameter,
    pasteErrors,
    successfulPaste,
    solution.show,
  ]);

  const bigFile =
    (jsonObject?.tours && jsonObject.tours.length > 30) ||
    (jsonObject?.plan?.jobs && jsonObject.plan.jobs.length > 100);

  const maxDisplayLength = bigFile ? 5 : 30;
  const defaultInspectDepth = bigFile ? 1 : 5;

  useEffect(() => {
    if (solution.jsonUserChange || resetValue || (pathReg && oldValueRef.current.length !== 0)) {
      setResetValue(false);
      if (!fileName) handleSetSolutionParameter({ param: { name: 'problem' } });
      if (solution.jsonPaste) {
        handleSetSolutionParameter({ param: { name: 'pasted' } });
        integrateFileText(
          JSON.stringify(jsonObject),
          'upload',
          'pasted',
          user,
          cookies,
          oAuth,
          dispatch,
          true,
          error,
          solution.show,
          false,
        );
      } else if (solution.jsonUserChange === 'draft') {
        recalculateMapData(
          dispatch,
          jsonObject,
          getLastValidIteration(solution.requests[solution.show])?.stopsNoLocation,
          territories.areaDetails,
          solution.show,
          solution.jsonUserChange === 'orders' && orders,
          depotMarkerTrans,
          returnLocationMarkerTrans,
        );
      } else {
        if (solution.jsonUserChange === 'orders') {
          const draggedOrder = orders.find((order) => order.Dragged);
          const jsonJobs = jsonObject.plan.jobs;
          const jsonOrder = jsonJobs.find((order) => order.id === draggedOrder.InternalID);
          if (jsonOrder) {
            const changedOrder = changeJsonOrderLocation(jsonOrder, draggedOrder);
            const jsonIdx = jsonJobs.indexOf(jsonOrder);
            const orderIdx = orders.indexOf(draggedOrder);
            jsonJobs[jsonIdx] = changedOrder;
            orders[orderIdx].Dragged = null;
          } else {
            const newJob = convertOrderToJson(draggedOrder);
            jsonObject.plan.jobs = [newJob, ...jsonJobs];
          }
        }
        if (solution.jsonUserChange === 'ordersDepot') {
          const location = tourPlanner.location.value;
          jsonObject.fleet.types[0].shifts[0].start.location = location;
        }
        if (solution.jsonUserChange === 'ordersEndLoc') {
          const location = tourPlanner.returnLocation.value;
          jsonObject.fleet.types[0].shifts[0].end.location = location;
        }
        const hasManualOrders =
          tourPlanner?.editedOrder && Object.keys(tourPlanner.editedOrder).length > 0;
        const ordersUserChange = solution.jsonUserChange === 'orders';

        recalculateSolutionParams(
          jsonObject,
          dispatch,
          error,
          fileName,
          getLastValidIteration(solution.requests[solution.show])?.stopsNoLocation,
          solution.show,
          false,
          fileExt,
          user.distance === 'imperial',
          (hasManualOrders || ordersUserChange) && orders,
          depotMarkerTrans,
          returnLocationMarkerTrans,
          ordersUserChange,
          hasManualOrders,
        );

        if (user.requestType === async) {
          handleSetSolutionParameter({
            param: { status: null, statusHref: null },
            index: solution.show,
          });
        }
      }
    }
  }, [
    cookies,
    dispatch,
    error,
    fileName,
    handleSetSolutionParameter,
    jsonObject,
    oAuth,
    pathReg,
    resetValue,
    solution.jsonPaste,
    solution.jsonUserChange,
    solution.requests,
    solution.show,
    territories,
    tourPlanner.ordersMode,
    user,
    fileExt,
    orders,
    depotMarkerTrans,
    returnLocationMarkerTrans,
    tourPlanner.editedOrder,
    tourPlanner.location.value,
    tourPlanner.returnLocation.value,
  ]);

  useEffect(() => {
    if (errorState && pathReg && oldValueRef.current.length !== 0) {
      const find = oldValueRef.current.filter((v) => pathReg.includes(v.adjustedPath));
      if (find.length >= 1) {
        find.forEach((f) => {
          setResetValue(true);
          setJsonObject((prev) =>
            removeProperties(applyValue(prev, f.path, removeAllWhitespaces(f.oldValue))),
          );
          handleSetProblemJsonParam({
            path: f.path,
            value: removeAllWhitespaces(f.oldValue),
            index: solution.show,
          });
        });
      }

      oldValueRef.current = [];
    }
  }, [errorState, handleSetProblemJsonParam, pathReg, solution.show, setResetValue]);

  const JsonComponent = (
    <JsonViewer
      id="react-file-drop-demo"
      value={jsonObject}
      theme={OCEAN_THEME}
      displayDataTypes={false}
      highlightUpdates
      rootName={false}
      editable={isEditable}
      onChange={onChange}
      onCopy={onCopy}
      displaySize={selectedOption === OPTIONS.PROBLEM || !bigFile}
      quotesOnKeys={false}
      maxDisplayLength={maxDisplayLength}
      defaultInspectDepth={defaultInspectDepth}
      collapseStringsAfterLength={30}
      indentWidth={4}
    />
  );

  useEffect(() => {
    if (solution.json) setSelectedOption(OPTIONS.PROBLEM);
  }, [solution.json]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    if (user.jsonPanelOpen) setShowJsonPanel(user.jsonPanelOpen);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
      if (!showJsonPanel) {
        handleSetUserParam({ jsonTourPanelOpen: false });
      }
    };
  }, [user.jsonPanelOpen, user.jsonTourPanelOpen, escFunction, showJsonPanel, handleSetUserParam]);

  return (
    <>
      <Modal isVisible={showJsonPanel} isFull fromRight>
        <StyledContrastPanel fullDisplay>
          <DevConsoleHeader
            setJsonObject={setJsonObject}
            setSelectedOption={setSelectedOption}
            setIsTourPanelOpen={setIsTourPanelOpen}
            isTourPanelOpen={isTourPanelOpen}
            selectedOption={selectedOption}
            togglePanel={togglePanel}
            escFunction={escFunction}
            showPanel={showJsonPanel}
            errorState={errorState}
          />
          <StyledJsonContainer ref={jsonRef} className="json-viewer-panel">
            <StyledFloatingBtnContainer>
              <ToastContainer hideProgressBar closeButton={false} />
              <StyledFloatingBtn
                data-test-id="pasteJson"
                icon={pasteIcon}
                onClick={onPaste}
                title={pasteJson}
                disabled={pasteDisabled}
              />
              <StyledFloatingBtn icon={copyIcon} onClick={onJsonCopy} order={2} title={copyJson} />
            </StyledFloatingBtnContainer>
            <FileUploadArea
              component={JsonComponent}
              jsonUpload
              handleJsonDrop={handleDrop}
              isJsonUploadClicked={isJsonUploadClicked}
              setIsJsonUploadClicked={setIsJsonUploadClicked}
              jsonRef={jsonRef.current}
              className="json-drop"
            />
          </StyledJsonContainer>
          <DevConsoleFooter
            setIsJsonUploadClicked={setIsJsonUploadClicked}
            selectedOption={selectedOption}
            jsonObject={jsonObject}
            filename={fileName}
            oAuth={oAuth}
            handleSetError={handleSetError}
            tourPlanner={tourPlanner}
          />
        </StyledContrastPanel>
      </Modal>
      {!showJsonPanel && (
        <StyledAccessContainer
          data-test-id="JSONViewer"
          onClick={() => togglePanel()}
          title={devConsoleLabels.configTitle}
        >
          <div>&nbsp;</div>
        </StyledAccessContainer>
      )}
    </>
  );
};

export default withTranslation(DevConsole);
